import { RemittanceAdviceModel, RemittanceInvoiceModel } from "../../app/data/documents/models";

export default interface RemittanceInvoicesState {
  request: {
    [key: string]: {
      requestStarted: boolean;
      requestSucceed: boolean;
      requestFailed: boolean;
      requestError?: string | null;
      nextPageId?: string;
    }
  }
  remittanceAdvices: RemittanceAdviceModel[];
  adviceInvoices: RemittanceInvoiceModel[];
  adviceEmail: string;
};

export const initialRemittanceInvoicesState: RemittanceInvoicesState = {
  request: {},
  remittanceAdvices: [],
  adviceInvoices: [],
  adviceEmail: "",
};