import { Routes } from "../../route/RoutesConfig";
import { AgingInvoicesPath } from "../../../features/invoices/aging-invoices/route";
import { InvoiceDetailsPath } from "../../../features/invoices/invoice-details/route";
import { PickupLogPath } from "../../../features/pickup/log/route";
import { RatesPath } from "../../../features/rates/route";
import { ReportsPath } from "../../../features/reports/route";
import { ShipmentDetailsPath } from "../../../features/shipments/shipment-details/route";

export const breadcrumbsRoutes = [
  {
    path: `/:number${Routes.home}`,
    breadcrumb: "Home"
  },
  {
    path: `/:number${Routes.agingInvoices}/:${AgingInvoicesPath.period}`,
    breadcrumb: "Invoices"
  },
  {
    path: `/:number${Routes.invoices}/:${InvoiceDetailsPath.invoiceNumber}`,
    breadcrumb: "Invoice Details"
  },
  {
    path: `/:number${Routes.shipments.tracking}`,
    breadcrumb: "Track Shipments"
  },
  {
    path: Routes.shipments.tracking,
    breadcrumb: "Track Shipments"
  },
  {
    path: Routes.shipments.filteredList,
    breadcrumb: "Track Shipments"
  },
  {
    path: `/:number${Routes.shipments.filteredList}`,
    breadcrumb: "Track Shipments"
  },
  {
    path: `${Routes.shipments.tracking}/:${ShipmentDetailsPath.invoiceNumber}`,
    breadcrumb: "Shipment Details"
  },
  {
    path: `/:number${Routes.shipments.tracking}/:${ShipmentDetailsPath.invoiceNumber}`,
    breadcrumb: "Shipment Details"
  },
  {
    path: Routes.pickup.create,
    breadcrumb: "Create Pickup"
  },
  {
    path: `/:number${Routes.pickup.create}`,
    breadcrumb: "Create Pickup"
  },
  {
    path: Routes.pickup.management,
    breadcrumb: "Pickup Assignment"
  },
  {
    path: `/:number${Routes.pickup.management}`,
    breadcrumb: "Pickup Assignment"
  },
  {
    path: Routes.pickup.log,
    breadcrumb: "Pickup Log"
  },
  {
    path: `/:number${Routes.pickup.log}`,
    breadcrumb: "Pickup Log"
  },
  {
    path: `${Routes.pickup.log}/:${PickupLogPath.pickupNumber}`,
    breadcrumb: "Pickup Log"
  },
  {
    path: `/:number${Routes.pickup.log}/:${PickupLogPath.pickupNumber}`,
    breadcrumb: "Pickup Log"
  },
  {
    path: Routes.deliveryRecords.list,
    breadcrumb: "Images"
  },
  {
    path: `/:number${Routes.deliveryRecords.list}`,
    breadcrumb: "Images"
  },
  {
    path: Routes.shipments.documents,
    breadcrumb: "Documents"
  },
  {
    path: `/:number${Routes.shipments.documents}`,
    breadcrumb: "Documents"
  },
  {
    path: Routes.shipments.manifestDocuments,
    breadcrumb: "Manifest Documents"
  },
  {
    path: `/:number${Routes.shipments.manifestDocuments}`,
    breadcrumb: "Manifest Documents"
  },
  {
    path: Routes.holidayCalendar,
    breadcrumb: "Holiday Calendar"
  },
  {
    path: `/:number${Routes.holidayCalendar}`,
    breadcrumb: "Holiday Calendar"
  },
  {
    path: Routes.deliveryRecords.new,
    breadcrumb: "Upload New Images"
  },
  {
    path: `/:number${Routes.deliveryRecords.new}`,
    breadcrumb: "Upload New Images"
  },
  // {
  //   path: `/:number${Routes.admin.jwtGenerator}`,
  //   breadcrumb: "API Tokens"
  // },
  {
    path: Routes.settings,
    breadcrumb: "Settings"
  },
  {
    path: `/:number${Routes.settings}`,
    breadcrumb: "Settings"
  },
  {
    path: `/:number${Routes.reports.scheduled.list}`,
    breadcrumb: "Scheduled Reports"
  },
  {
    path: `/:number${Routes.reports.scheduled.create}`,
    breadcrumb: "Create New Report"
  },
  {
    path: `/:number${Routes.reports.scheduled.list}/:${ReportsPath.id}`,
    breadcrumb: "Report Details"
  },
  {
    path: `/:number${Routes.rates.list}`,
    breadcrumb: "Rate Quotes"
  },
  {
    path: `/:number${Routes.rates.guided}`,
    breadcrumb: "New Request"
  },
  {
    path: `/:number${Routes.rates.quick}`,
    breadcrumb: "New Request"
  },
  {
    path: `/:number${Routes.rates.list}/:${RatesPath.id}`,
    breadcrumb: "Rates Details"
  },
  {
    path: `/:number${Routes.bolCombined}`,
    breadcrumb: "Bill of Lading"
  },  
  {
    path: `/:number${Routes.bol.list}`,
    breadcrumb: "BOL"
  },
  {
    path: `/:number${Routes.bol.create}`,
    breadcrumb: "Create New BOL"
  },
  {
    path: `/:number${Routes.return.list}`,
    breadcrumb: "Returns"
  },
  {
    path: `/:number${Routes.return.create}`,
    breadcrumb: "Create New Return"
  },
  {
    path: `/:number${Routes.pickups.list}`,
    breadcrumb: "Pickups"
  },
  {
    path: `/:number${Routes.claims.list}`,
    breadcrumb: "Claims"
  },
  {
    path: `/:number${Routes.claims.create}`,
    breadcrumb: "Create New Claim"
  },
  {
    path: `/:number${Routes.transitCalculator}`,
    breadcrumb: "Transit Calculator"
  },
  {
    path: `/:number${Routes.company.general}`,
    breadcrumb: "Company Settings"
  },
  {
    path: Routes.manifest.manifests,
    breadcrumb: "Track Manifests"
  },
  {
    path: `/:number${Routes.manifest.manifests}`,
    breadcrumb: "Track Manifests"
  },
  {
    path: Routes.customers.list,
    breadcrumb: "Customers"
  },
  {
    path: `/:number${Routes.customers.list}`,
    breadcrumb: "Customers"
  },
  {
    path: Routes.agents.list,
    breadcrumb: "Agents"
  },
  {
    path: `/:number${Routes.agents.list}`,
    breadcrumb: "Agents"
  },
  {
    path: Routes.users.list,
    breadcrumb: "Users"
  },
  {
    path: `/:number${Routes.users.list}`,
    breadcrumb: "Users"
  },
  {
    path: Routes.customers.pending,
    breadcrumb: "Pending Requests"
  },
  {
    path: `/:number${Routes.customers.pending}`,
    breadcrumb: "Pending Requests"
  }, 
  {
    path: Routes.announcement,
    breadcrumb: "Announcement"
  },
  {
    path: `/:number${Routes.announcement}`,
    breadcrumb: "Announcement"
  },
  {
    path: Routes.featureBanner,
    breadcrumb: "Feature Banner"
  },
  {
    path: `/:number${Routes.featureBanner}`,
    breadcrumb: "Feature Banner"
  },  
  {
    path: Routes.shipments.appointments,
    breadcrumb: "Appointments"
  },
  {
    path: `/:number${Routes.shipments.appointments}`,
    breadcrumb: "Appointments"
  },
  {
    path: Routes.linehaulMileage.management,
    breadcrumb: "Linehaul Lane Management"
  },
  {
    path: `/:number${Routes.linehaulMileage.management}`,
    breadcrumb: "Linehaul Lane Management"
  },
  {
    path: Routes.linehaulMileage.mileage,
    breadcrumb: "Linehaul Mileage"
  },
  {
    path: `/:number${Routes.linehaulMileage.mileage}`,
    breadcrumb: "Linehaul Mileage"
  },
  {
    path: Routes.dispatch.planRoute,
    breadcrumb: "Plan Route"
  },
  {
    path: `/:number${Routes.dispatch.planRoute}`,
    breadcrumb: "Plan Route"
  },
  {
    path: Routes.dispatch.activeRoutes,
    breadcrumb: "Active Routes"
  },
  {
    path: `/:number${Routes.dispatch.activeRoutes}`,
    breadcrumb: "Active Routes"
  },
  {
    path: Routes.dispatch.picklists,
    breadcrumb: "Picklists"
  },
  {
    path: `/:number${Routes.dispatch.picklists}`,
    breadcrumb: "Picklists"
  },
  {
    path: Routes.dispatch.unplannableProbills,
    breadcrumb: "Unplannable Probills"
  },
  {
    path: `/:number${Routes.dispatch.unplannableProbills}`,
    breadcrumb: "Unplannable Probills"
  },
  {
    path: Routes.ai.shipmentChat,
    breadcrumb: "Shipment Chat"
  },
  {
    path: `/:number${Routes.ai.shipmentChat}`,
    breadcrumb: "Shipment Chat"
  },
  {
    path: Routes.warehouseInventory.summary,
    breadcrumb: "Inventory Summary"
  },
  {
    path: Routes.warehouseInventory.inventory,
    breadcrumb: "Inventory"
  },
  {
    path: Routes.documents.podRecognition,
    breadcrumb: "POD Recognition"
  },
  {
    path: `/:number${Routes.documents.podRecognition}`,
    breadcrumb: "POD Recognition"
  },
  {
    path: Routes.documents.recognitionLogs,
    breadcrumb: "Recognition Logs"
  },
  {
    path: `/:number${Routes.documents.recognitionLogs}`,
    breadcrumb: "Recognition Logs"
  },
  {
    path: `/:number${Routes.documents.view}`,
    breadcrumb: "ImageView"
  },
  {
    path: `/:number${Routes.documents.index}`,
    breadcrumb: "ImageIndexer"
  },
  {
    path: `/:number${Routes.documents.generate}`,
    breadcrumb: "InvoiceGenerator"
  },
  {
    path: `/:number${Routes.documents.generateView}`,
    breadcrumb: "Invoice Viewer"
  },
  {
    path: `/:number${Routes.pickup.inboundSummary}`,
    breadcrumb: "Outbound Planning Summary"
  },
  {
    path: `/:number${Routes.pickup.inboundPickups}`,
    breadcrumb: "Outbound Planning List"
  },
  {
    path: Routes.documents.invoices,
    breadcrumb: "Invoices"
  },
  {
    path: `/:number${Routes.documents.invoices}`,
    breadcrumb: "Invoices"
  },
  {
    path: Routes.dockLoading.list,
    breadcrumb: "Dock Loading Status"
  },
  {
    path: `/:number${Routes.dockLoading.list}`,
    breadcrumb: "Dock Loading Status"
  },
  {
    path: Routes.dispatch.serviceDisruption,
    breadcrumb: "Service Disruption Management"
  },
  {
    path: `/:number${Routes.dispatch.serviceDisruption}`,
    breadcrumb: "Service Disruption Management"
  },
  {
    path: `/:number${Routes.billing.pickupApproval}`,
    breadcrumb: "Pickup Approval"
  },
  {
    path: Routes.emergencyAlertManagement,
    breadcrumb: "Emergency Alert"
  },
  {
    path: `/:number${Routes.emergencyAlertManagement}`,
    breadcrumb: "Emergency Alert"
  },
  {
    path: Routes.documents.remittanceInvoices,
    breadcrumb: "Remittance invoices"
  },
  {
    path: `/:number${Routes.documents.remittanceInvoices}`,
    breadcrumb: "Remittance invoices"
  }
];
