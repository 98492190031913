import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";

import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import Button, { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import NumberFormat from "react-number-format";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Table from "../../../ui-components/table/table";
import { ReactComponent as LinkIcon } from "../../../images/link.svg";
import { ReactComponent as ClearIcon } from "../../../images/clear.svg";
import LabeledRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-range-input/labeledRangeInput";
import RemittanceInvoicesSelectedBar from "./remittanceInvoicesSelectedBar";
import { ReactComponent as WarningIcon } from "../../../images/warning.svg";
import { ReactComponent as MoreIcon } from "../../../images/more.svg";
import AdviceDetails from "./adviceDetails";
import EmailDetails from "./emailDetails";

import { RemittanceAdviceEmail, RemittanceAdviceModel, RemittanceInvoicesFilterModel, RemittanceInvoicesFilterSchema } from "../../../app/data/documents/models";
import { getAdviceEmail, getAdviceInvoices, getRemittanceAdvices, remittanceInvoicesSelector, setAdviceEmail } from "../../../slices/documents/remittanceInvoicesSlice";
import { REQUEST_ID } from "../../../app/data/documents/remittanceInvoicesService";

import "./remittanceInvoices.scss";

import { PAGE_IDS } from "../../../app/data/common/pageIds";

const initialFilterValues: RemittanceInvoicesFilterModel = {
  invoiceNumber: "",
  customerName: "",
  paymentStartDate: "",
  paymentEndDate: "",
  minPaymentAmount: "",
  maxPaymentAmount: "",
  adviceReceivedStartDate: "",
  adviceReceivedEndDate: "",
};

const columnHelper = createColumnHelper<RemittanceAdviceModel>();

const RemittanceInvoices: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const invoicesState = useSelector(remittanceInvoicesSelector);
  const [adviceDetailsId, setAdviceDetailsId] = useState<number | null>(null);  
  const [adviceCustomer, setAdviceCustomer] = useState<string | null>(null);
  const [emailDetailsId, setEmailDetailsId] = useState<number | null>(null);
  const [emailDetails, setEmailDetails] = useState<RemittanceAdviceEmail | null>(null);
  const [exportDateRange, setExportDateRange] = useState<number>(0);
  const filterFormRef = useRef<FormikProps<RemittanceInvoicesFilterModel>>(null);
  const lastFilter = useRef<RemittanceInvoicesFilterModel>({});

  const warningCell = (status: string) => (
    <span style={{ padding: "9px" }}>
      {status === "IN_PROGRESS"
      ? <MoreIcon style={{ color: "#2F80ED", width: "14px", height: "14px" }} />
      : <WarningIcon style={{ color: "#E82A2B", width: "14px", height: "14px" }} /> 
      }
    </span>
  ); 

  const columns = [
    columnHelper.accessor("id", {
      header: "Advice ID",      
      cell: (cellProps) => (
        <span
          className="xgs-table-link-cell"
          onClick={(e) => {
            e.stopPropagation();
            onShowAdviceDetails(cellProps.row.original);
          }}
        >
          {cellProps.getValue()}
        </span>
      ),
      size: 100,
    }),  
    columnHelper.accessor("adviceReceivedDate", {
      header: "Received Date",
      cell: (cellProps) => (cellProps.getValue()?.toUiDateFormat() || warningCell(cellProps.row.original.processingStatus)),
      size: 110,
    }),        
    columnHelper.accessor("customerName", {
      header: "Customer",
      cell: (cellProps) => (cellProps.getValue() || warningCell(cellProps.row.original.processingStatus)),
      size: 200,      
    }),
    columnHelper.accessor("totalPayment", {
      header: "Total Amount",
      size: 120,
      cell: (cellProps) => (cellProps.getValue()
        ? (<div className="xgs-table__cell-amount">
          <span>$&nbsp;</span>
          <span className="xgs-table__cell-amount__value">
            <NumberFormat
              value={cellProps.getValue()}
              decimalScale={2}
              displayType={"text"}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </span>
        </div>)
        : warningCell(cellProps.row.original.processingStatus)
      )
    }),
    columnHelper.accessor("paymentDate", {
      header: "Payment Date",
      cell: (cellProps) => (cellProps.getValue()?.toUiDateFormat() || warningCell(cellProps.row.original.processingStatus)),
      size: 104,
    }),
    columnHelper.accessor("numberOfInvoices", {
      header: "Invoices",
      cell: (cellProps) => (cellProps.getValue() || warningCell(cellProps.row.original.processingStatus)),
      size: 104,
    }),  
    columnHelper.accessor("email.sender", {
      id: "emailFrom",
      header: "Email From",
      size: 160,
    }),    
    columnHelper.display({
      header: "Source",      
      cell: (cellProps) => (
        <Button
          theme={ButtonThemes.transparent}
          size={ButtonSizes.auto}
          onClick={(e) => {
            e.stopPropagation();
            onShowEmailDetails(cellProps.row.original);
          }}
        >
          View Email&nbsp;<LinkIcon />
        </Button>
      ),
      size: 130,
      minSize: 130,
    }),
  ];

  let clearSelection: (() => void) | null = null;

  const assignClearSelection = (f: () => void) => {
    clearSelection = f;
  };

  const formattedFilter = (values: RemittanceInvoicesFilterModel) => ({
    ...values,
    ...values.paymentStartDate && { paymentStartDate: moment(values.paymentStartDate).format("YYYY-MM-DD") },
    ...values.paymentEndDate && { paymentEndDate: moment(values.paymentEndDate).format("YYYY-MM-DD") },
    ...values.adviceReceivedStartDate && { adviceReceivedStartDate: moment(values.adviceReceivedStartDate).format("YYYY-MM-DD") },
    ...values.adviceReceivedEndDate && { adviceReceivedEndDate: moment(values.adviceReceivedEndDate).format("YYYY-MM-DD") },
    ...values.minPaymentAmount && { minPaymentAmount: Math.round(values.minPaymentAmount) },
    ...values.maxPaymentAmount && { maxPaymentAmount: Math.round(values.maxPaymentAmount) }
  });

  const onSubmitFilter = (values: RemittanceInvoicesFilterModel) => {
    clearSelection?.();
    const filterValues = formattedFilter(values);
    
    dispatch(getRemittanceAdvices(filterValues));
    lastFilter.current = filterValues;
    setAdviceDetailsId(null);
    setEmailDetailsId(null);
  };

  const onResetFilter = () => {
    filterFormRef.current?.resetForm();
    filterFormRef.current?.submitForm();
  }

  const onListInfiniteScroll = () => {
    const nextPage = invoicesState.request[REQUEST_ID.GET_REMITTANCE_ADVICES]?.nextPageId;
    if (!nextPage) return;
    dispatch(getRemittanceAdvices(lastFilter.current, nextPage));
  };

  const renderInvoicesBar = (rows: any[], toggleAllRowsSelected: (selected: boolean) => void, isAllRowsSelected: boolean) => {
    return (
      <RemittanceInvoicesSelectedBar
        selectedAdvices={rows}
        onReset={() => { toggleAllRowsSelected(false) }}
        isAllRowsSelected={isAllRowsSelected}
        filter={filterFormRef.current?.values ? formattedFilter(filterFormRef.current?.values) : null}
        exportDateRange={exportDateRange}
      />
    )
  }

  const onShowAdviceDetails = useCallback((advice: RemittanceAdviceModel) => {
    if (adviceDetailsId !== advice.id) {
      setAdviceDetailsId(advice.id);
      setAdviceCustomer(advice.customerName)
      dispatch(getAdviceInvoices(advice.id));
    }
  }, [dispatch, adviceDetailsId]);

  const onShowEmailDetails = useCallback((advice: RemittanceAdviceModel) => {
    if (emailDetailsId !== advice.id) {
      setEmailDetails(advice.email);
      setEmailDetailsId(advice.id);
      dispatch(getAdviceEmail(advice.id));
    }
  }, [dispatch, emailDetailsId]);

  const getExportDateRange = (paymentStartDate: string | undefined, paymentEndDate: string | undefined) => (
    (paymentStartDate && paymentEndDate)
      ? moment(paymentEndDate, "MM/DD/YYYY").diff(moment(paymentStartDate, "MM/DD/YYYY"), "days")
      : 0
  );

  useEffect(() => {
    dispatch(getRemittanceAdvices(initialFilterValues));
  }, [dispatch]);

  return (
    <ContentContainer
      className="xgs-list xgs-list--full-width"
      mods={{ "full-width": true }}
      titleComponent={
        <ContentContainerToolbar
          pageId={PAGE_IDS.REMITTANCE_INVOICES}
          title="Remittance Invoices"
          mods={{ "full-width": true }}
        >
        </ContentContainerToolbar>
      }
    >
      <div className="remittance-invoices">
        <div className="xgs-list__controls">
          <div className="xgs-list__controls__search">
            <Formik
              initialValues={initialFilterValues}
              onSubmit={onSubmitFilter}
              innerRef={filterFormRef}
              validationSchema={RemittanceInvoicesFilterSchema}
            >
              {(props: FormikProps<RemittanceInvoicesFilterModel>) => (
                <Form className="xgs-list__controls__search-form">                  
                  <XGSFormInput
                    type="text"
                    name="customerName"
                    label="Customer"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field"
                  />
                  <XGSFormInput
                    type="text"
                    name="invoiceNumber"
                    label="Invoice #"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field"
                  />

                  <LabeledDateRangeInput
                    label="Received Date Range"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field xgs-list__controls__date-range"
                    start={props.values.adviceReceivedStartDate}
                    end={props.values.adviceReceivedEndDate}
                    onStartChange={(v) => {
                      props.setFieldValue("adviceReceivedStartDate", v || undefined);
                    }}
                    onEndChange={(v) => {
                      props.setFieldValue("adviceReceivedEndDate", v || undefined);
                    }}
                  />

                  <LabeledDateRangeInput
                    label="Payment Date Range"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field xgs-list__controls__date-range"
                    start={props.values.paymentStartDate}
                    end={props.values.paymentEndDate}
                    onStartChange={(v) => {
                      props.setFieldValue("paymentStartDate", v || undefined);
                      setExportDateRange(getExportDateRange(v, props.values.paymentEndDate));
                    }}
                    onEndChange={(v) => {
                      props.setFieldValue("paymentEndDate", v || undefined);
                      setExportDateRange(getExportDateRange(props.values.paymentStartDate, v));
                    }}
                  />
                  <LabeledRangeInput
                    className="xgs-item-form__field"
                    label="Total Amount Range"
                    labelMode={LabelModes.column}
                  >
                    <XGSFormInput
                      className="xgs-field-range__field"
                      type="text"
                      name="minPaymentAmount"
                      labelMode={LabelModes.column}
                    />
                    <div className="xgs-field-range__divider">-</div>
                    <XGSFormInput
                      className="xgs-field-range__field"
                      type="text"
                      name="maxPaymentAmount"
                      labelMode={LabelModes.column}
                    />
                  </LabeledRangeInput>                  

                  <div className="remittance-invoices__buttons">
                    <Button
                      className="remittance-invoices__search-button"
                      type="submit"
                      theme={ButtonThemes.blue}
                      spinner={invoicesState.request[REQUEST_ID.GET_REMITTANCE_ADVICES]?.requestStarted}
                    >
                      Search
                    </Button>

                    <Button
                      className="remittance-invoices__clear-button"
                      type="button"
                      theme={ButtonThemes.gray}
                      size={ButtonSizes.autoMedium}
                      onClick={onResetFilter}
                    >
                      <ClearIcon />
                    </Button>
                  </div>                  
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="remittance-invoices__list">
          {(invoicesState.request[REQUEST_ID.GET_REMITTANCE_ADVICES]?.requestFailed) && (
            <XGSErrorMessage>{invoicesState.request[REQUEST_ID.GET_REMITTANCE_ADVICES]?.requestError}</XGSErrorMessage>
          )}
          {!(invoicesState.request[REQUEST_ID.GET_REMITTANCE_ADVICES]?.requestFailed) && (
            <Table
              isLoading={invoicesState.request[REQUEST_ID.GET_REMITTANCE_ADVICES]?.requestStarted}
              keepTableOnLoading
              columns={columns}
              data={invoicesState.remittanceAdvices}
              rowHeight={56}
              minTableHeight={440}
              noResultsText="There are no records"
              responsive
              enableRowSelection
              renderSelectedBar={renderInvoicesBar}
              infiniteScroll={true}
              infiniteScrollLoading={!!(invoicesState.request[REQUEST_ID.GET_REMITTANCE_ADVICES]?.nextPageId && invoicesState.request[REQUEST_ID.GET_REMITTANCE_ADVICES].requestStarted)}
              infiniteScrollHasNext={!!invoicesState.request[REQUEST_ID.GET_REMITTANCE_ADVICES]?.nextPageId}
              onInfiniteScroll={onListInfiniteScroll}
              clearSelection={assignClearSelection}
            />
          )}
        </div>

        <AdviceDetails
          isOpen={!!adviceDetailsId}
          isLoading={invoicesState.request[REQUEST_ID.GET_REMITTANCE_INVOICES]?.requestStarted}
          isError={!!invoicesState.request[REQUEST_ID.GET_REMITTANCE_INVOICES]?.requestError}
          invoices={invoicesState.adviceInvoices}
          customer={adviceCustomer}
          onClose={() => setAdviceDetailsId(null)}
        />

        <EmailDetails
          key={emailDetailsId || undefined}
          isOpen={!!emailDetailsId}
          adviceId={emailDetailsId}
          email={emailDetails}
          rawEmail={invoicesState.adviceEmail}
          onClose={() => {
            setEmailDetailsId(null);
            dispatch(setAdviceEmail(null));
          }}
          isLoading={invoicesState.request[REQUEST_ID.GET_ADVICE_EMAIL]?.requestStarted}
          isError={!!invoicesState.request[REQUEST_ID.GET_ADVICE_EMAIL]?.requestError}
        />
      </div>
    </ContentContainer>
  );
};

export default RemittanceInvoices;