import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getStandingAppointment,
  standingAppointmentSelector,
} from "../../../slices/customers/standing-appointment/standingAppointmentSlice";
import "../../company/standing-appointment/standingAppointment.scss";
import {
  AccountOpenHoursType,
  DayOfWeek,
  StandingAppointmentHours,
} from "../../../app/data/standing-appointment/models";
import Loading from "../../../ui-components/loading/loading";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { ReactComponent as ScheduleIcon } from "../../../images/schedule_unavailable.svg";
import styles from "../../../sass/variables.module.scss";
import { accountInapplicableInformationSelector } from "../../../slices/account-inapplicable-information/accountInapplicableInformationSlice";

export const AvailabilityTime: React.FC<{ accountId: string, type: AccountOpenHoursType }> = ({
  accountId,
  type
}) => {
  const standingAppointmentState = useSelector(standingAppointmentSelector);
  const accountInapplicableInformationState = useSelector(accountInapplicableInformationSelector);
  
  const dispatch = useDispatch();

  const typeEnabled = !accountInapplicableInformationState.disabledInformationTypes.includes(type);

  const getFormattedHours = (day?: StandingAppointmentHours) => {
    if (!day?.fromTime || !day?.toTime) return "-";

    return `${moment(day.fromTime, "HH:mm:ss").format("hh:mm a")} - ${moment(day.toTime, "HH:mm:ss").format("hh:mm a")}`
  }

  useEffect(() => {
    if (!accountId) return;
    dispatch(getStandingAppointment(accountId, type));
  }, [accountId, type, dispatch]);

  let dailyHoursMap = standingAppointmentState.standingAppointments?.dailyHoursMap;

  const isLoading = (standingAppointmentState.requestStarted && standingAppointmentState.requestCreator === "GET") || standingAppointmentState.loadingServiceDays;
      
  return (
    <>
      {isLoading && <Loading isLoading={isLoading}/>}
      {standingAppointmentState.loadingServiceDaysFailed && <XGSErrorMessage>Could not find service days, please try again later</XGSErrorMessage>}
      {!isLoading && (
          <div className="probill-preview__grid">
            {Object.values(DayOfWeek).map((day) => {
              const isNonServiceDay = standingAppointmentState.serviceDays ? !standingAppointmentState.serviceDays[day] : false;
              const isClosed = dailyHoursMap?.[day] && !dailyHoursMap[day].isOpen;

              return (
                <>
                  <span className="probill-preview__section__item__label">
                    {day.toLowerCase().capitalize()}:
                  </span>

                  <div className="probill-preview__grid__column">
                    <span className="probill-preview__section__item__value">
                      {typeEnabled
                        ? (
                          <>
                            {isClosed
                              ? "Closed"
                              : getFormattedHours(dailyHoursMap?.[day])
                            }
                          </>
                        )
                        : "-"
                      }                      
                    </span>

                    {isNonServiceDay && typeEnabled &&
                      <ScheduleIcon color={styles.red} />
                    }
                  </div>
                </>
              );
            })}
          </div>
        )}
    </>
  );
};
