import React, { useCallback, useEffect, useRef, useState } from "react";
import PostalMime from "postal-mime";
import Popup from "reactjs-popup";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import { ReactComponent as NoteIcon } from "../../../images/note-2_blue.svg";
import { ReactComponent as ImageIcon } from "../../../images/image.svg";
import { ReactComponent as WarningIcon } from "../../../images/warning.svg";
import { ReactComponent as LinkIcon } from "../../../images/link.svg";
import Loading from "../../../ui-components/loading/loading";
import { RemittanceAdviceEmail } from "../../../app/data/documents/models";

export interface EmailDetailsProps {
  adviceId: number | null;
  email: RemittanceAdviceEmail | null;
  rawEmail: string;
  onClose: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  isError?: boolean;
};

const IMAGE_TYPES = ["image/png", "image/jpeg"];

const EmailDetails: React.FC<EmailDetailsProps> = ({ isOpen, isLoading, isError, adviceId, email, rawEmail, onClose }) => {
  const [parsedHtml, setParsedHtml] = useState<any>(null);
  const [parsedText, setParsedText] = useState<any>(null);
  const [attachments, setAttachments] = useState<any>(null);
  const [isParsing, setIsParsing] = useState(false);
  const [previewHeight, setPreviewHeight] = useState(50);
  const previewRef = useRef<HTMLIFrameElement | null>(null);

  const parseEmail = useCallback(async () => {
    if (!rawEmail) {
      setParsedHtml(null);
      return;
    }

    setIsParsing(true);

    const result = await PostalMime.parse(rawEmail, { attachmentEncoding: "base64" });
    const images = result.attachments.filter(attachment => attachment.disposition === "inline");
    const { html, text } = result;

    if (html) {
      const email = images.reduce((result, image) => (
        image.contentId ? result?.replace(`cid:${image.contentId.substring(1, image.contentId.length - 1)}`, `data:image/jpeg;base64,${image.content}`) : result
      ), html);
      setParsedHtml(email);
    } else {
      setParsedText(text);
    }
    

    setIsParsing(false);
  }, [rawEmail]);

  const parseAttachments = useCallback(async () => {
    if (!rawEmail) {
      setAttachments(null);
      return;
    }

    setIsParsing(true);

    const result = await PostalMime.parse(rawEmail);
    const documents = result.attachments.filter(attachment => attachment.disposition === "attachment");
    setAttachments(documents);

    setIsParsing(false);
  }, [rawEmail]);

  const downloadAttachment = (attachment: any) => {
    let blob: Blob = new Blob([attachment.content], { type: attachment.mimeType });
    let documentfileURL = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = documentfileURL;
    link.download = attachment.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    parseEmail();
  }, [parseEmail]);

  useEffect(() => {
    parseAttachments();
  }, [parseAttachments]);

  return (
    <SlideOutSidebar
      header="Email Details"
      onClose={onClose}
      show={isOpen}
    >
      <div className="remittance-invoices__email">
      <div className="remittance-invoices__email__header">{`Advice ID: ${adviceId}`}</div>
      
        <div className="remittance-invoices__email__headers">
          <div className="remittance-invoices__email__headers__row">
            <div className="remittance-invoices__email__headers__item"><span className="remittance-invoices__email__label">From:&nbsp;</span>{email?.sender}</div>
            <div className="remittance-invoices__email__headers__item"><span className="remittance-invoices__email__label">To:&nbsp;</span>{email?.receiver}</div>
          </div>

          <div className="remittance-invoices__email__headers__item"><span className="remittance-invoices__email__label">Subject:&nbsp;</span>{email?.subject}</div>
          <div className="remittance-invoices__email__headers__item"><span className="remittance-invoices__email__label">Sent:&nbsp;</span>{email?.sentAt.toUiDateTimeFormat()}</div>
        </div>

        <div className="remittance-invoices__email__download">
          <span className="remittance-invoices__email__subtitle">Email</span>
          <Popup
                  trigger={
                    <div>
                      <a className="blue-link" href={email?.webLink} target="_blank" rel="noreferrer"><LinkIcon />&nbsp;Open in Outlook</a>
                    </div>
                  }
                  position="bottom right"
                  on={["hover"]}
                  offsetY={5}
                  arrowStyle={{
                    borderWidth: "0px",
                    transform: "translate(40px)",
                  }}
                  contentStyle={{
                    borderRadius: "4px",
                    top: "50px",
                    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.20)",
                    borderWidth: "0px",
                    padding: "16px",
                    width: "150px",
                  }}
                >
                  <div className="remittance-invoices__email__tooltip">
                     Original email will be available based on permissions.
                  </div>                  
                </Popup>          
        </div>

        {(isLoading || isParsing)
          ? <Loading isLoading={true} />
          : isError ? (
            <div className="remittance-invoices__details__error">
              <div className="remittance-invoices__details__error__title"> <WarningIcon /> Error </div>
              <div>No email information found.</div>
            </div>
          )
            : (
              <>
              <div className="remittance-invoices__email__attachments">
                  {attachments?.map((attachment: any) => (
                    <div key={attachment.contentId} className="remittance-invoices__email__attachment" onClick={() => { downloadAttachment(attachment) }}>
                      <div className="remittance-invoices__email__icon">{IMAGE_TYPES.includes(attachment.mimeType) ? <ImageIcon /> : <NoteIcon />}</div>
                      <div className="remittance-invoices__email__attachment__content">
                        <div className="remittance-invoices__email__attachment__link">{attachment.filename}</div>
                        <div className="remittance-invoices__email__label">{attachment.filename.split('.').pop().toUpperCase()}</div>
                      </div>
                    </div>
                  ))}
                </div>
                
                <div className="remittance-invoices__email__content">
                {parsedHtml && <div className="remittance-invoices__email__email">
                    <iframe 
                      ref={previewRef}
                      title="preview"
                       style={{ borderWidth: 0 }} 
                       srcDoc={parsedHtml || null} 
                       width="100%" 
                       height={previewHeight}
                       onLoad={() => {setPreviewHeight(previewRef.current?.contentWindow?.document.documentElement.scrollHeight || 50)}}
                       />
                  </div>}
                  {!parsedHtml && parsedText && <div className="remittance-invoices__email__email">
                    <pre>{parsedText}</pre>
                  </div>
                  }
                </div>                
              </>
            )
        }
      </div>
    </SlideOutSidebar>
  );
};

export default EmailDetails;
