import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import {
  serviceCentersSelector,
  getServiceCenters
} from "../../../slices/service-centers/serviceCentersSlice";
import { pickupAssignmentSelector } from "../../../slices/pickup/pickupAssignmentSlice";
import { PickupStatus } from "../../../app/data/common/route";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { PickupsFilterModel } from "../../../app/data/pickup/models";

export interface PickupListFiltersProps {
  onSetExpiredOnly: (v: boolean) => void;
  onSetStatuses: (v: PickupStatus[]) => void;
  onSetPickupSearch: (v: string) => void;
  onSetShowFuture: (v: boolean) => void;
  formRef: any;
  forceResetStatuses?: boolean;
};

const defaultStatuses = [
  { label: "Missed", value: "MISSED" },
  { label: "Unassigned", value: "UNASSIGNED" },
  { label: "Assigned", value: "ASSIGNED" }
];

const initialValues = { 
  "pickupSearch": "" 
};

const PickupListFilters: React.FC<PickupListFiltersProps> = ({onSetStatuses, ...props}) => {
  const dispatch = useDispatch();
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);
  const serviceCentersState = useSelector(serviceCentersSelector);
  const [statuses, setStatuses] = useState<XGSSelectOption | null | undefined>();
  const [expiredOnly, setExpiredOnly] = useState(false);
  const [showFuture, setShowFuture] = useState(false);

  const getStatusesOptions = () => {
    const keys = Object.keys(PickupStatus)
    const statusesOptions = [];
    keys.forEach((key, index) => {
      let label = key.toLowerCase();
      label = label.charAt(0).toUpperCase() + label.slice(1);
      statusesOptions.push({
        label: label,
        value: key.toString()
      })
    });
    return statusesOptions;
  };

  const onExpiredOnlyChange = (checked: boolean) => {
    if (checked) {
      onStatusChange(defaultStatuses);
    }
    setExpiredOnly(checked);
    props.onSetExpiredOnly(checked);
  };

  const onStatusChange = (v: any) => {
    setStatuses(v);
    let statuses = v ? v.map(option => option.value) : [];
    onSetStatuses(statuses);
  };

  const onShowFutureChange = (checked: boolean) => {    
    setShowFuture(checked);
    props.onSetShowFuture(checked);
  };

  const onSearch = (values: PickupsFilterModel) => {
    props.onSetPickupSearch(values.pickupSearch);
  };

  useEffect(() => {
    if (!serviceCentersState.loaded && !serviceCentersState.loading) dispatch(getServiceCenters());
  }, [dispatch, serviceCentersState]);

  useEffect(() => {
    onStatusChange(defaultStatuses);
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if (!props.forceResetStatuses) return;
    onExpiredOnlyChange(false);
    onShowFutureChange(true);
    onStatusChange(getStatusesOptions());
    // eslint-disable-next-line
  }, [props.forceResetStatuses]);

  return (
    <div className="xgs-pickup-assignment__orders__filters">
      <div className="xgs-pickup-assignment__orders__filters__block">
        <div className="xgs-pickup-assignment__orders__filters__control">
          <div className="xgs-pickup-assignment__orders__filters__control__toggle">
            <Switch
              onChange={(checked) => onExpiredOnlyChange(checked)}
              checked={expiredOnly}
              disabled={pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS" || pickupAssignmentState.requestCreator === "GET_PICKUPS_PORTION")}
              width={32}
              height={20}
              offColor="#BDBDBD"
              onColor="#2F80ED"
              uncheckedIcon={<></>}
              checkedIcon={<></>}
            />
          </div>
          <div className="xgs-pickup-assignment__orders__filters__control__label">
            Expired Only
          </div>
        </div>

        <div className="xgs-pickup-assignment__orders__filters__control">
          <div className="xgs-pickup-assignment__orders__filters__control__toggle">
            <Switch
              onChange={(checked) => onShowFutureChange(checked)}
              checked={showFuture}
              disabled={pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS" || pickupAssignmentState.requestCreator === "GET_PICKUPS_PORTION")}
              width={32}
              height={20}
              offColor="#BDBDBD"
              onColor="#2F80ED"
              uncheckedIcon={<></>}
              checkedIcon={<></>}
            />
          </div>
          <div className="xgs-pickup-assignment__orders__filters__control__label">
            Show Future Pickups
          </div>
        </div>
      </div>

      <div className="xgs-pickup-assignment__orders__filters__control">
        <LabeledSelectInput
          onMultiValuesChange={(option) => onStatusChange(option)}
          value={statuses}
          options={getStatusesOptions()}
          label="Statuses:"
          labelMode={LabelModes.row}
          className="xgs-pickup-assignment__orders__filters__control__input xgs-pickup-assignment__orders__filters__control__input--wide"
          isClearable={false}
          isMulti
          disabled={
            (pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS" || pickupAssignmentState.requestCreator === "GET_PICKUPS_PORTION"))
            || expiredOnly
          }
        />
      </div>
      
      <Formik
        onSubmit={onSearch}
        initialValues={initialValues}
        innerRef={props.formRef}
      >
        {(formikProps: FormikProps<PickupsFilterModel>) => (
          <Form className="xgs-pickup-assignment__orders__filters__control">
            <XGSFormInput
              label="Pickup/BOL #:"
              labelMode={LabelModes.row}
              className="xgs-pickup-assignment__orders__filters__control__input"
              name="pickupSearch"
              type="text"
              placeholder="e.g. PU00012345"
              maxLength={30}
              clearable
              onClear={() => { 
                props.formRef.current?.setFieldValue("pickupSearch", "");
                props.formRef.current?.submitForm();
              }}
            />
            <Button
              type="submit"
              theme={ButtonThemes.blue}
              className="xgs-pickup-assignment__orders__filters__control__button"
              disabled={pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS" || pickupAssignmentState.requestCreator === "GET_PICKUPS_PORTION")}

            >
              Search
            </Button>
          </Form>)}
      </Formik>
    </div>
  );
};

export default PickupListFilters;
