import React from "react";
import { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import DownloadButton from "../../../ui-components/download-button/downloadButton";
import { RemittanceAdviceModel, RemittanceInvoicesFilterModel } from "../../../app/data/documents/models";
import RemittanceInvoicesService from "../../../app/data/documents/remittanceInvoicesService";
import { useDownload } from "../../../hooks/useDownload";
import {ReactComponent as ExclamationIcon} from "../../../images/exclamation.svg";

interface RemittanceInvoicesSelectedBarProps {
  selectedAdvices: RemittanceAdviceModel[];
  onReset: () => void;
  isAllRowsSelected: boolean;
  filter: RemittanceInvoicesFilterModel | null,
  exportDateRange: number,
}

const invoicesService = RemittanceInvoicesService.getInstance();

const RemittanceInvoicesSelectedBar: React.FC<RemittanceInvoicesSelectedBarProps> = (props) => {
  const getInvoicesCount = (advices: any[]) => {
    return advices.reduce((result: number, row: any) => (row.original.numberOfInvoices ? (result + row.original.numberOfInvoices) : result), 0);
  }

  const fetchCSV = () => {
    const params = props.isAllRowsSelected
      ? { isIncludeAllResults: true, ...props.filter }
      : { adviceIds: props.selectedAdvices.map((row: any) => row.original.id).join(",") }
    return invoicesService.getCsvExport(params);
  };

  const {
    download,
    loadingState,
  } = useDownload(
    "REMITTANCE_INVOICES",
    fetchCSV,
  );

  const downloadDisabled = props.isAllRowsSelected
    ? !props.exportDateRange || props.exportDateRange > 15
    : false;

  return (
    <div className="remittance-invoices__selected-bar">
      <div className="remittance-invoices__selected-bar__content">
        <div className="xgs-table__selected-bar__item remittance-invoices__mobile-hidden">
          {props.isAllRowsSelected
            ? <div className="xgs-table__selected-bar__warning remittance-invoices__selected-bar__warning">
              <div className="remittance-invoices__selected-bar__warning__icon">
              <ExclamationIcon/>
              </div>
              <div>
                {props.exportDateRange > 15
                  ? <>
                    <div className="remittance-invoices__selected-bar__warning__main">You have selected too many invoices.</div>
                    <div>Please specify a Payment Date Range within 15 business days.</div>
                  </>
                  : <>
                    <div className="remittance-invoices__selected-bar__warning__main">You have selected all available invoices.</div>
                    <div>Please specify a Payment Date Range of no more than 15 business days.</div>
                  </>
                }
              </div>
            </div>
            : <>
              <div>
                <span className="xgs-table__selected-bar__summary-value">Selection Summary</span>
              </div>
              <div>
                <span className="xgs-table__selected-bar__summary-label">Advices Selected:</span>
                &nbsp;
                <span className="xgs-table__selected-bar__summary-value">{props.selectedAdvices.length}</span>
              </div>
              <div>
                <span className="xgs-table__selected-bar__summary-label">Invoices Included:</span>
                &nbsp;
                <span className="xgs-table__selected-bar__summary-value">{getInvoicesCount(props.selectedAdvices)}</span>
              </div>
            </>
          }
        </div>
        <div className="xgs-table__selected-bar__item">
          <DownloadButton
            theme={ButtonThemes.blue}
            size={ButtonSizes.autoMedium}
            title={props.isAllRowsSelected ? "Download All" : "Download Selected"}
            onClick={download}
            spinner={loadingState?.downloadStarted}
            disabled={downloadDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default RemittanceInvoicesSelectedBar;