import React from "react";

import "./xgsFieldRange.scss";
import { cn } from "../../services/common/className";

export interface XGSFieldRangeProps {
  mix?: string;
}

const XGSFieldRange: React.FC<XGSFieldRangeProps> = (props) => {
  return (
    <div className={cn("xgs-field-range")(null, props.mix)}>
      {props.children}
    </div>
  );
}

export default XGSFieldRange;