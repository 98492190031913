import React from "react";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import DownloadPDF from "../../download-invoice-pdf/downloadPDF";

interface InvoicesSelectedBarProps {
  selectedInvoices: number[];
  onReset: () => void; 
}

const InvoicesSelectedBar: React.FC<InvoicesSelectedBarProps> = (props) => {
  return (
    <div className="xgs-documents__invoices__selected-bar xgs-table__selected-bar__content">
      <div className="xgs-table__selected-bar__item xgs-documents__invoices__mobile-hidden">
        <div>
          <span className="xgs-table__selected-bar__summary-label">Invoices Selected:</span>
          &nbsp;
          <span className="xgs-table__selected-bar__summary-value">{props.selectedInvoices.length}</span>
        </div>
      </div>
      <div className="xgs-table__selected-bar__item">
        <Button theme={ButtonThemes.gray} onClick={props.onReset}>Clear Selection</Button>

        <DownloadPDF theme={ButtonThemes.blue} invoiceNumbers={props.selectedInvoices} type="multiple" />
      </div>
    </div>
  );
};

export default InvoicesSelectedBar;