import React, { ReactElement, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import XGSPopup from "../../ui-components/xgs-popup/xgsPopup";
import "../../ui-components/map/map.scss";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import Button, { ButtonThemes, ButtonSizes } from "../../ui-components/button/button";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import TrailerLocationState from "../../slices/trailer-location/TrailerLocationState";
import {
  getTrailerLocation,
  resetTrailerLocation,
  trailerLocationSelector
} from "../../slices/trailer-location/trailerLocationSlice";
import Loading from "../../ui-components/loading/loading";
import useInterval from "../../hooks/useInterval";
import XGSMap from "../../ui-components/map/map";
import XGSCustomMarker from "../../ui-components/map/custom-marker/customMarker";
import { TRUCK_MARKER } from "../../services/map";
import XGSMarkerTooltip from "../../ui-components/map/marker-tooltip/markerTooltip";
import StopMarkerInfo from "../../ui-components/map/stop-marker-info/stopMarkerInfo";
import { Wrapper } from "@googlemaps/react-wrapper";

import "./trailerLocation.scss";

export interface TrailerLocationProps {
  invoiceNumber: number;
  textMode?: boolean;
};

const TrailerLocation: React.FC<TrailerLocationProps> = (props) => {
  const dispatch = useDispatch();
  const trailerLocationState: TrailerLocationState = useSelector(trailerLocationSelector);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onClose = () => {
    setShowModal(false);

    if (trailerLocationState.requestError) {
      dispatch(resetTrailerLocation());
    }
  };

  const trailerLocation = trailerLocationState.result ? {
    lat: trailerLocationState.result.latitude,
    lng: trailerLocationState.result.longitude
  } : undefined;

  const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_KEY || ""; 

  const render = (): ReactElement => {
    return <Loading isLoading={true} />;
  };

  useEffect(() => {
    if (!props.invoiceNumber) return;
    if (showModal) {
      dispatch(getTrailerLocation(props.invoiceNumber, false));
    }

    return () => {
      dispatch(resetTrailerLocation());
    };
    // eslint-disable-next-line
  }, [showModal]);

  useInterval(() => {
    if (!props.invoiceNumber || !showModal) return;
    dispatch(getTrailerLocation(props.invoiceNumber, true));
  }, 30000);

  return (
    <>
      <div className={`xgs-trailer-locations__open__container ${props.textMode ? " xgs-trailer-locations__open__container--text-mode" : ""}`}>
        {props.textMode ? (
          <div
            className="xgs-trailer-locations__open__text-link blue-link"
            onClick={() => setShowModal(true)}
          >
            <XGSIcon
              icon={XGSIcons.faMapMarkedAlt}
              size="sm"
              className="xgs-trailer-locations__open__text-link__icon"
            />
            Show on map
          </div>
        ) : (
          <Button
            theme={ButtonThemes.green}
            size={ButtonSizes.medium}
            type="button"
            className="xgs-trailer-locations__open__button"
            onClick={() => setShowModal(true)}
          >
            Show on map
          </Button>
        )}
      </div>
      <div>
        <XGSPopup
          title="Trailer Location"
          opened={showModal}
          onClose={onClose}
          className="xgs-trailer-locations__popup"
        >
          {trailerLocationState.requestSucceed && trailerLocationState.result &&
            <div className="xgs-trailer-locations__popup__address">
              <span>Address:</span>{trailerLocationState.result.location}
            </div>
          }
          <div className="xgs-trailer-locations__popup__content">
            <Loading isLoading={trailerLocationState.requestStarted} />
            {trailerLocationState.requestFailed && (
              <XGSErrorMessage className="xgs-trailer-locations__popup__content__error">{trailerLocationState.requestError}</XGSErrorMessage>
            )}
            {trailerLocationState.requestSucceed && trailerLocationState.result && (
              <div className="xgs-map__container">
                <Wrapper
                  apiKey={apiKey}
                  render={render}
                  libraries={["geometry", "marker"]}
                >
                  <XGSMap
                    center={trailerLocation}
                    zoom={11}
                    traffic={true}
                    zoomControl={true}
                  >
                    <XGSCustomMarker
                      position={{
                        lat: trailerLocationState.result.latitude,
                        lng: trailerLocationState.result.longitude
                      }}
                      path={TRUCK_MARKER.PATH}
                      fillColor={TRUCK_MARKER.COLOR}
                      tooltip={<XGSMarkerTooltip><StopMarkerInfo info={{ address: trailerLocationState.result.location }} /></XGSMarkerTooltip>}
                    />
                  </XGSMap>
                </Wrapper>                
              </div>
            )}
            {trailerLocationState.requestSucceed && !trailerLocationState.result && (
              <span>No location data</span>
            )}
          </div>
        </XGSPopup>
      </div>
    </>
  );
};

export default TrailerLocation;
