export const HUMAN_FRIENDLY_ACTIONS: {[key: string]: string} = {
  "ASSIGNED": "Assigned to",
  "CANCELED": "Canceled",
  "COMPLETED": "Completed by",
  "CREATED": "Created",
  "EMAIL_SENT": "The email was sent to",
  "EXIST_PROBILL": "Wrong probill number",
  "ITEM_UPDATE": "Items changed",
  "MISSED": "Became missed",
  "RESCHEDULED_FROM": "Rescheduled from",
  "RESCHEDULED_TO": "Rescheduled to",
  "SAVE_PICKUP_ARRIVAL_INFO": "Arrived at",
  "UNASSIGNED": "Unassigned"
};