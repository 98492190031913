import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { PermissionResponseModel } from "../../../app/data/user/models";
import TeamUsersState from "../../../slices/customers/users/TeamUsersState";
import {
  clearUserPermissions,
  getUserPermissions,
  updateAccountPermissionsLocally,
  usersSelector
} from "../../../slices/customers/users/teamUsersSlice";
import CompanySettingsAccountsState from "../../../slices/company-settings/CompanySettingsAccountsState";
import {
  companySettingsAccountsSelector,
  resetErrors,
  updatePermissions
} from "../../../slices/company-settings/companySettingsAccountsSlice";
import TeamAccountState from "../../../slices/customers/teams/TeamAccountState";
import {
  teamAccountSelector,
  searchTeamAccounts
} from "../../../slices/customers/teams/teamAccountSlice";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { permissionTypes } from "../constants";
import "./userDetails.scss";

export interface UserDetailsAccountsPermissionsProps {
  teamId?: string;
  userId: string;
  permissions: PermissionResponseModel[];
}

const UserDetailsAccountsPermissions: React.FC<UserDetailsAccountsPermissionsProps> = (props) => {
  const dispatch = useDispatch()
;  const teamUsersState: TeamUsersState = useSelector(usersSelector);
  const companySettingsAccountsState: CompanySettingsAccountsState = useSelector(companySettingsAccountsSelector);
  const teamAccountState: TeamAccountState = useSelector(teamAccountSelector);
  const [addAccountShow, setAddAccountShow] = useState<boolean>();

  const onPermissionChange = (
    accountId: string,
    permissions: XGSSelectOption[] | null | undefined
  ) => {
    let userPermissions = (permissions && permissions.length > 0) ? permissions.map((obj: XGSSelectOption) => obj.value.toString()) : ["VIEW"];
    dispatch(updateAccountPermissionsLocally({
      userId: props.userId,
      accountId: accountId,
      permissions: userPermissions
    }));
    dispatch(updatePermissions(accountId, props.userId, userPermissions));
  };

  const onPermissionsRemove = (accountId: string) => {
    dispatch(updateAccountPermissionsLocally({
      userId: props.userId,
      accountId: accountId,
      permissions: []
    }));
    dispatch(updatePermissions(accountId, props.userId, []));
  };

  const getSelectValue = (permissions: string[]) => {
    let result: XGSSelectOption[] = [];
    for (let permission of permissions) {
      const type = permissionTypes.find(type => type.value === permission);
      type && result.push(type);
    }
    return result;
  };

  let accountsSearch = (inputValue: string, callback: (params: any) => void) => {
    if (!inputValue) return callback([]);
    dispatch(searchTeamAccounts(inputValue, props.teamId || "", (accounts: any) => {
      callback(accounts
        .filter((account: any) => !props.permissions.find((permissionsObj: PermissionResponseModel) => permissionsObj.account.id === account.id))
        .map((account: any) => ({
          label: account.name,
          value: account.id,
          subtitle: account.accountNumber,
          valueForSearch: `${account.name}|${account.accountNumber}`,
        }))
      );
    }));
  };
  accountsSearch = debounce(accountsSearch, 500);

  const onAccountSelect = (account: XGSSelectOption | null | undefined) => {
    if (!account?.value) return;
    setAddAccountShow(false);
    dispatch(updateAccountPermissionsLocally({
      isNew: true,
      userId: props.userId,
      accountId: account.value,
      accountName: account.label,
      accountNumber: account.subtitle,
      permissions: ["VIEW"]
    }));
    dispatch(updatePermissions(account.value.toString(), props.userId, ["VIEW"]));
  };

  useEffect(() => {
    if (!props.userId) return;
    dispatch(clearUserPermissions());
    dispatch(getUserPermissions(props.userId));
  }, [dispatch, props.userId]);

  useEffect(() => {
    if (companySettingsAccountsState.update_was_failed) {
      toast.error(companySettingsAccountsState.update_fail_reason || "Error while updating the permissions!");
      dispatch(resetErrors());
    }
  }, [dispatch, companySettingsAccountsState.update_was_failed, companySettingsAccountsState.update_fail_reason]);

  return (
    <div className="xgs-company__users__details__permissions">
      <div className="xgs-company__users__details__permissions__add-account">
        <Button
          type="button"
          theme={ButtonThemes.blue}
          onClick={() => setAddAccountShow(true)}
        >
          Add Account
        </Button>
      </div>
      {addAccountShow && (
        <div className="xgs-company__accounts__details__add-user__permissions__add">
          <LabeledSelectInput
            label=""
            isDisabled={false}
            labelMode={LabelModes.column}
            placeholder="Type account name or number..."
            onValueChange={onAccountSelect}
            async={true}
            className="xgs-company__accounts__details__add-user__permissions__add__dropdown"
            loadOptions={accountsSearch}
            options={[]}
            isLoading={teamAccountState.request_was_started}
          />
          <Button
            className="xgs-company__small-button"
            type="button"
            theme={ButtonThemes.gray}
            spinner={false}
            onClick={() => setAddAccountShow(false)}>
            <XGSIcon
              icon={XGSIcons.faTimes}
              size="sm"
            />
          </Button>
        </div>
      )}
      {props.permissions && (props.permissions.length > 0) && props.permissions.map((obj: PermissionResponseModel) => (
        <div className="xgs-company__accounts__details__add-user__permissions__row xgs-company__accounts__details__add-user__permissions__row--user-details" key={obj.account.id}>
          <div className="xgs-company__accounts__details__add-user__permissions__name">
            <strong>{obj.account.name}</strong> ({obj.account.accountNumber})
          </div>
          <div className="xgs-company__accounts__details__add-user__permissions__select">
            <LabeledSelectInput
              onMultiValuesChange={(option) => onPermissionChange(obj.account.id, option)}
              options={permissionTypes}
              label=""
              labelMode={LabelModes.column}
              isMulti
              isClearable
              disabled={companySettingsAccountsState.update_was_started}
              value={getSelectValue(obj.permissions)}
            />
          </div>
          <div className="xgs-company__accounts__details__add-user__permissions__remove">
            <Button
              className="xgs-company__small-button"
              type="button"
              theme={ButtonThemes.gray}
              spinner={false}
              onClick={() => onPermissionsRemove(obj.account.id)}>
              <XGSIcon
                icon={XGSIcons.faTimes}
                size="sm"
              />
            </Button>
          </div>
        </div>
      ))}
      {(!teamUsersState.userPermissions || teamUsersState.userPermissions.length === 0) && !(teamUsersState.fetch_was_started && teamUsersState.requestCreator === "GET_PERMISSIONS") && (
        <span className="xgs-company__users__details__no-permissions">There are no permissions set yet.</span>
      )}
    </div>
  );
};

export default UserDetailsAccountsPermissions;
