import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Tag from "../../../ui-components/molecules/tag/tag";
import { getStatusColor } from "../../../services/pickups";

const columnHelper = createColumnHelper<any>();

export const getInboundPickupColumns = () => [
  columnHelper.accessor("pickup.pickupNumber", {
    id: "pickupNumber",
    header: "Pickup #",
    cell: (props) => (
      <div className="blue-link">
        {props.getValue()}
      </div>
    ),
    size: 120,
  }),
  columnHelper.accessor("pickupFullData.pickupRequest.consigneeName", {
    id: "consigneeName",
    header: "Consignee",
    size: 120,
  }),
  columnHelper.accessor("pickupFullData.pickupRequest.shipperName", {
    id: "shipperName",
    header: "Shipper",
    size: 120,
  }),
  columnHelper.accessor("shipper", {
    id: "shipperAddress",
    header: "Shipper address",
    cell: (props) => {
      const { address } = props.getValue() || {};
      return (
        <>
          {address?.address1}, {address?.city}, {address?.state}, {address?.postalCode}
        </>
      );
    },
    size: 292,
  }),
  columnHelper.accessor("destinationServiceCenter", {
    id: "destinationServiceCenter",
    header: "Destination Terminal",
    cell: (props) => {
      const value = props.getValue();
      return value ? (
        <>
          {value.airportCode} - {value.terminalName?.toUpperCase()}{" "}
          <span style={{ color: "rgba(79, 95, 114, 0.72)" }}>
            {value.terminalNumber}
          </span>
        </>
      ) : (
        <></>
      );
    },
    size: 200,
  }),
  columnHelper.accessor("items.count", {
    id: "itemsCount",
    header: "Items",
    size: 60,
  }),
  columnHelper.accessor("items.weight", {
    id: "itemsWeight",
    header: "Weight",
    size: 80,
  }),
  columnHelper.accessor("pickup.driverName", {
    id: "driverName",
    header: "Driver",
    size: 120,
  }),
  columnHelper.accessor("pickup.pickupStatus", {
    id: "pickupStatus",
    header: "Status",
    cell: (props) => (
      <Tag mods={{ color: getStatusColor(props.getValue()), uppercase: true }}>
        {props.getValue()}
      </Tag>
    ),
    size: 130,
  }),
];
