import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { initialRemittanceInvoicesState } from "./RemittanceInvoicesState";
import { RemittanceInvoicesFilterModel } from "../../app/data/documents/models";
import { AppThunk } from "../../app/store";
import RemittanceInvoicesService, { REQUEST_ID } from "../../app/data/documents/remittanceInvoicesService";

const remittanceInvoicesService = RemittanceInvoicesService.getInstance();

export const remittanceInvoicesSlice = createSlice({
  name: "remittanceInvoices",
  initialState: initialRemittanceInvoicesState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.request[payload.requester] = {
        requestStarted: true,
        requestSucceed: false,
        requestFailed: false,
        requestError: null,
      };
    },
    requestSucceed: (state, { payload }) => {
      state.request[payload.requester] = {
        requestStarted: false,
        requestSucceed: true,
        requestFailed: false,
        nextPageId: payload.nextPageId,
      };      
    },
    requestFailed: (state, { payload }) => {
      state.request[payload.requester] = {
        requestStarted: false,
        requestSucceed: false,
        requestFailed: true,
        requestError: payload.error,
      };
    },    
    setRemittanceAdvices: (state, { payload }) => {
      state.remittanceAdvices = payload;
    },
    addRemittanceAdvices: (state, { payload }) => {
      state.remittanceAdvices = [...state.remittanceAdvices, ...payload];
    },
    setAdviceInvoices: (state, { payload }) => {
      state.adviceInvoices = payload;
    },
    setAdviceEmail: (state, { payload }) => {
      state.adviceEmail = payload;
    },
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setRemittanceAdvices,
  addRemittanceAdvices,
  setAdviceInvoices,
  setAdviceEmail,
} = remittanceInvoicesSlice.actions;

export const getRemittanceAdvices = (
  filter: RemittanceInvoicesFilterModel,
  pageId?: string,
): AppThunk => async (dispatch) => {
  dispatch(requestStarted({ requester: REQUEST_ID.GET_REMITTANCE_ADVICES }));
  const response = await remittanceInvoicesService.getAdvices(filter, pageId);
  if (response.isCancel?.()) return;
  if (response.ok()) {    
    dispatch(pageId ? addRemittanceAdvices(response.data.remittanceAdvices) : setRemittanceAdvices(response.data.remittanceAdvices));
    dispatch(requestSucceed({ requester:  REQUEST_ID.GET_REMITTANCE_ADVICES, nextPageId: response.data.pagination.next, }));
  } else {
    dispatch(requestFailed({ requester:  REQUEST_ID.GET_REMITTANCE_ADVICES, error: response.getError ? response.getError() : "Error"}));
  }
};

export const getAdviceInvoices = (
  adviceId: number,
): AppThunk => async (dispatch) => {
  dispatch(requestStarted({ requester: REQUEST_ID.GET_REMITTANCE_INVOICES }));
  const response = await remittanceInvoicesService.getAdviceInvoices(adviceId);
  if (response.isCancel?.()) return;
  if (response.ok()) {    
    dispatch(setAdviceInvoices(response.data.invoices));
    dispatch(requestSucceed({ requester:  REQUEST_ID.GET_REMITTANCE_INVOICES }));
  } else {
    dispatch(requestFailed({ requester:  REQUEST_ID.GET_REMITTANCE_INVOICES, error: response.getError ? response.getError() : "Error"}));
  }
};

export const getAdviceEmail = (
  adviceId: number,
): AppThunk => async (dispatch) => {
  dispatch(requestStarted({ requester: REQUEST_ID.GET_ADVICE_EMAIL }));
  const response = await remittanceInvoicesService.getAdviceEmail(adviceId);
  if (response.isCancel?.()) return;
  if (response.ok()) {    
    dispatch(setAdviceEmail(response.data));
    dispatch(requestSucceed({ requester:  REQUEST_ID.GET_ADVICE_EMAIL }));
  } else {
    dispatch(requestFailed({ requester:  REQUEST_ID.GET_ADVICE_EMAIL, error: response.getError ? response.getError() : "Error"}));
  }
};

export const remittanceInvoicesSelector = (state: IState) => state.remittanceInvoices;

const remittanceInvoicesReducer = remittanceInvoicesSlice.reducer;
export default remittanceInvoicesReducer;
