import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui-components/table/table";
import {
  clearPickupLog,
  getPickupLog,
  pickupDetailsSelector
} from "../../../slices/pickup/pickupDetailsSlice";
import { HUMAN_FRIENDLY_ACTIONS } from "../../pickup/common";
import { PickupLogItem } from "../../../app/data/pickup/models";
import LocationPopup from "../../common/location-popup/locationPopup";
import { ReactComponent as PointIcon } from "../../../images/point.svg";
import "./pickupDetails.scss";

export interface PickupLogProps {
  pickupId: string;
  onRescheduled: (arg: { pickupNumber: string, pickupDate: string } | null) => void;
};

const columnHelper = createColumnHelper<PickupLogItem>();

const PickupDetailsLog: React.FC<PickupLogProps> = ({onRescheduled, ...props}) => {
  const dispatch = useDispatch();
  const pickupDetailsState = useSelector(pickupDetailsSelector);

  const columns = [
    columnHelper.accessor("actionExecutedTime", {
      id: "actionExecutedTime",      
      header: "Event Time",
      cell: (cellProps) => (
        <>
          {cellProps.getValue() && (
            <div className="xgs-pickup-details__log__date">
              <div>{cellProps.getValue().toUiDateFormat()}</div> <div className="xgs-pickup-details__log__gray-value">{cellProps.getValue().toUiTimeShortFormat()}</div>
            </div>
          )}
        </>
      ),
      size: 145,
    }),
    columnHelper.accessor("userExecuted", {
      id: "userExecuted",      
      header: "User",
      size: 208,
    }),
    columnHelper.accessor("actionPerformed", {
      id: "actionPerformed",
      header: "Action",      
      cell: (cellProps) => (
        <>{!!cellProps.getValue() && (HUMAN_FRIENDLY_ACTIONS[cellProps.getValue()] ? HUMAN_FRIENDLY_ACTIONS[cellProps.getValue()] : cellProps.getValue())}</>
      ),
      size: 155,
    }),
    columnHelper.display({
      id: "additionalInfo",
      size: 170,
      header: "Additional Info",
      cell: ({ row }) => (
        <>
          {(row.original.actionPerformed === "SAVE_PICKUP_ARRIVAL_INFO") && (
            <>
              {(!row.original.pickupArrivalLatitude || !row.original.pickupArrivalLongitude) && (
                <>pickup location (coordinates not provided)</>
              )}
              {!!row.original.pickupArrivalLatitude && !!row.original.pickupArrivalLongitude && (
                <LocationPopup
                  lat={row.original.pickupArrivalLatitude}
                  lng={row.original.pickupArrivalLongitude}
                  header="Pickup Location"
                  hideFullscreen
                >
                  <div className="xgs-pickup-log__additional-info__v-centered-item"><PointIcon /> <span className="blue-link">pickup location</span></div>
                </LocationPopup>
              )}
            </>
          )}
          {!!row.original.notes && (
            <span className="xgs-pickup-details__log__additional__item">
              {row.original.notes}
            </span>
          )}
          {!!row.original.driverName && (
            <span className="xgs-pickup-details__log__additional__item">
             {row.original.driverName} {row.original.driverId ? (<span className="xgs-pickup-details__log__gray-value">({row.original.driverId})</span>) : ""}
            </span>
          )}
          {!row.original.driverName && !!row.original.driverId && (
            <span className="xgs-pickup-details__log__additional__item">
             {row.original.driverId}
            </span>
          )}
          {!!row.original.pickupNumber && (
            <>
             {row.original.pickupNumber}
            </>
          )}
        </>
      )
    })
  ];

  useEffect(() => {
    if (!props.pickupId) return;
    dispatch(getPickupLog(props.pickupId));
  }, [props.pickupId, dispatch]);

  useEffect(() => {
    if (!pickupDetailsState.pickupLog) return;
    let rescheduledEntry = pickupDetailsState.pickupLog.find(entry => entry.actionPerformed === "RESCHEDULED_TO");
    onRescheduled(rescheduledEntry ? { pickupNumber: rescheduledEntry.pickupNumber, pickupDate: rescheduledEntry.pickupDate } : null);
  }, [pickupDetailsState.pickupLog, onRescheduled]);

  useEffect(() => {
    return () => {dispatch(clearPickupLog())};
  }, [dispatch]);

  return (
    <div className="xgs-pickup-details__log">
      <Table
        isLoading={pickupDetailsState.requestStarted && (pickupDetailsState.requestCreator === "GET_PICKUP_LOG")}
        columns={columns}
        data={pickupDetailsState.pickupLog}
        minTableHeight={220}
        noResultsText="No entries"
      />
    </div>
  );
};

export default PickupDetailsLog;
