import React, { useEffect, useCallback, memo } from "react";
import { useLocation } from "react-router-dom";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import { useHistory } from "react-router";
import "./filter.scss";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { useDispatch, useSelector } from "react-redux";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { fetchAllTerminals } from "../../../../slices/warehouse-inventory/warehouseInventorySlice";
import { getServiceCenters, serviceCentersSelector } from "../../../../slices/service-centers/serviceCentersSlice";
import { InboundPickupStatus } from "../../../../app/data/common/route";
import queryString from "query-string";

type TrackingFilterProps = {
  sameRowButtons?: boolean;
  isLoading?: boolean;
  onSearch: (filters: any) => void;
  onClear?: () => void;
};

const defaultStatuses = [
  {
    label: "ASSIGNED",
    value: "ASSIGNED"
  },
  {
    label: "COMPLETED",
    value: "COMPLETED"
  }
]

export interface InboundPickupsFilters {
  o?: string | null;
  d?: number | null;
  s?: string[] | null;
}

function parseQueryString<T>(queryStringInput: string): T {
  let parsed = queryString.parse(queryStringInput, {
    arrayFormat: "bracket",
    parseBooleans: true,
  }) as T;
  return parsed;
}

export const InboundPickupsFilter: React.FC<TrackingFilterProps> = memo(
  ({ onSearch, onClear, ...props }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const serviceCentersState = useSelector(serviceCentersSelector);
  
    const getDestinationOptions = () => {
      return (
        serviceCentersState.destinations?.map(
          (destination): XGSSelectOption => ({
            label: destination.name,
            value: destination.id.toString(),
          })
        ) || []
      );
    };
  
    const stringifyQuery = (query: Record<string, any>) => {
      return queryString.stringify(query, { arrayFormat: "bracket", skipNull: true, skipEmptyString: true });
    };
  

    const getFiltersFromURL = useCallback(() => {
      const queryParams = parseQueryString<InboundPickupsFilters>(location.search);
      return queryParams;
    }, [location.search]);

    const handleSearch = () => {
      const filters = getFiltersFromURL();
      onSearch(filters);
    };
  
    const handleFilterChange = (key: keyof InboundPickupsFilters, value: any, triggerSearch?: boolean) => {
      const filters = getFiltersFromURL();
      filters[key] = value;
      history.push({ search: stringifyQuery(filters) })
      triggerSearch && onSearch(filters);
    };

    const handleClear = () => {
      let filters = {s: defaultStatuses.map(s => s.value)}
      history.push({ search: stringifyQuery(filters) })
      onSearch(filters)
      onClear && onClear();
    };

    useEffect(() => {
      dispatch(fetchAllTerminals());
    }, [dispatch]);

    const onDestinationChanged = (_origin: XGSSelectOption | null | undefined) => {
      handleFilterChange("d", _origin?.value, true);
    };

    const getStatusesOptions = () => {
      const keys = Object.keys(InboundPickupStatus)
      const statusesOptions: XGSSelectOption[] = [];
      keys.forEach((key, index) => {
        let label = key.toLowerCase();
        label = label.charAt(0).toUpperCase() + label.slice(1);
        statusesOptions.push({
          label: label,
          value: key.toString()
        })
      });
      return statusesOptions;
    };

    const onStatusChange = (v: any) => {
      let statuses = v ? v.map((option: XGSSelectOption) => option.value) : [];
      handleFilterChange("s", statuses, true);
    };
  
    let currentFilters = getFiltersFromURL();

    useEffect(() => {
      if (!serviceCentersState.loaded && !serviceCentersState.loading) dispatch(getServiceCenters());
    }, [dispatch, serviceCentersState]);

    useEffect(() => {
      handleSearch();
      // eslint-disable-next-line
    }, [location])
  
    useEffect(() => {
      if (!currentFilters.s?.length) {
        onStatusChange(defaultStatuses);
      } else {
        handleSearch();
        
      }
      // eslint-disable-next-line
    }, [])

    const selectedStatusesStrings = currentFilters.s;
    const allStatusOptions = getStatusesOptions();
    const selectedStatusesOptions = selectedStatusesStrings?.map(status => allStatusOptions.find(option => option.value === status)).filter((v) => !!v);

    return (
      <div className="xgs-inbound-pickups-filter">
        <div className="xgs-inbound-pickups-filter__controls">
          <div className="xgs-inbound-pickups-filter__controls__item">
            <LabeledSelectInput
              isLoading={serviceCentersState.loading}
              onValueChange={onDestinationChanged}
              value={getDestinationOptions().find(d => d.value + "" === currentFilters.d + "") || null}
              options={getDestinationOptions()}
              label="Destination Terminal:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
              isClearable
            />
          </div>
          <div className="xgs-inbound-pickups-filter__controls__large-item">
            <LabeledSelectInput
              onMultiValuesChange={(option) => onStatusChange(option)}
              value={selectedStatusesOptions}
              options={getStatusesOptions()}
              label="Statuses:"
              labelMode={LabelModes.column}
              isClearable={false}
              isMulti
            />
          </div>
          <div className="xgs-inbound-pickups-filter__buttons xgs-inbound-pickups-filter__buttons--small xgs-inbound-pickups-filter__controls__item">
            <Button theme={ButtonThemes.blue} onClick={handleSearch}>
              Search
            </Button>
            <Button theme={ButtonThemes.gray} onClick={handleClear}>
              Clear Filters
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
