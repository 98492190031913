import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface PodRecognitionItemModel {
  id: string;
  file: string;
  filename: string;
  dateCreated: string;
  fromAgent: string;
  successRecognition: boolean;
  errorMessage: string;
  probillNumber: number | null;
  blobURI: string;
  isMaster: boolean;
};

export interface PodRecognitionFilterModel {
  probill?: number | string;
  from?: string;
  to?: string;
  agent?: string;
  success?: boolean;
};

export const PodRecognitionFilterSchema: Yup.ObjectSchema<PodRecognitionFilterModel> = Yup.object().shape({
  probill: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999999, "The value must be no more than 999999999")
    .integer("The value must be an integer")
    .typeError(validationMessages.notValid),
  from: Yup.string(),
  to: Yup.string(),
  agent: Yup.string().max(60, "The value must be no more than 60 characters"),
  success: Yup.boolean()
}).defined();

export interface RecognitionLogsItemModel {
  datetime: string;
  filename: string;
};

export interface RemittanceAdviceEmail {
  id: number;
  sender: string;
  receiver: string;
  subject: string;
  sentAt: string;
  webLink: string;
}

export interface RemittanceAdviceModel {
  id: number;
  createdBy: string;
  paymentDate: string;
  adviceReceivedDate: string;
  customerName: string;
  totalPayment: string;
  email: RemittanceAdviceEmail;
  numberOfInvoices: number;
  processingStatus: string;
};

export interface RemittanceAdviceResponseModel {
  remittanceAdvices: RemittanceAdviceModel[];
};

export interface RemittanceInvoiceModel {
  invoiceNumber: number;
  amountPaid: number;
}

export interface RemittanceInvoiceResponseModel {
  invoices: RemittanceInvoiceModel[];
};

export interface RemittanceInvoicesFilterModel {
  invoiceNumber?: number;
  customer?: string;
  paymentStartDate?: string;
  paymentEndDate?: string;
  minPaymentAmount?: number;
  maxPaymentAmount?: number;
  adviceReceivedStartDate?: string;
  adviceReceivedEndDate?: string;
};

export const RemittanceInvoicesFilterSchema: Yup.ObjectSchema<RemittanceInvoicesFilterModel> = Yup.object().shape({
  invoiceNumber: Yup.number()
    .min(0, "The value must be no less than 0")
    .max(999999999, "The value must be no more than 999999999")
    .integer("The value must be an integer")
    .typeError(validationMessages.notValid),
  customerName: Yup.string().max(256, "The value must be no more than 256 characters"),
  paymentStartDate: Yup.string(),
  paymentEndDate: Yup.string(),
  minPaymentAmount: Yup.number()
  .typeError(validationMessages.notValid)
  .integer("The value must be a valid amount without decimal values")
  .max(100000000, "Amount should be less than 100000000"),
  maxPaymentAmount: Yup.number()
  .typeError(validationMessages.notValid)
  .integer("The value must be a valid amount without decimal values")
  .max(100000000, "Amount should be less than 100000000"),
  adviceReceivedStartDate: Yup.string(),
  adviceReceivedEndDate: Yup.string(),
}).defined();
export interface InvoiceResponseModel {
  invoiceNumber: number;
  invoice: {
    paid: boolean;
    invoiceAmount: number;
    dueDate: string;
    paymentDate: string;
  },
  payor: {
    info: string;
  },
  status: {
    delivered: boolean;
  },
  newStatus: InvoicePaymentStatus,
};

export enum InvoicePaymentStatus {
  paid = "PAID",
  pending = "PENDING",
  overdue = "OVERDUE",
}

export enum InvoiceDeliveryStatus {
  delivered = "delivered",
  pending = "pending",
}

export interface InvoicesFilterModel {
  probill?: number;
  customer?: string;
  fromDate?: string;
  toDate?: string;
  deliveryApproach?: string,
  missed?: string | boolean,
  missedPeriod?: string,
  paymentStatus?: string,
};

export const InvoicesFilterSchema: Yup.ObjectSchema<InvoicesFilterModel> = Yup.object().shape({
  probill: Yup.number(),
  customer: Yup.string(),
  from: Yup.string(),
  to: Yup.string(),
}).defined();
