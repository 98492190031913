import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import Loading from "../../../ui-components/loading/loading";
import ThreeDotsMenu from "../../../ui-components/three-dots-menu/threeDotsMenu";
import Button, { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import {
  getPickupLog,
} from "../../../slices/pickup/pickupDetailsSlice";
import { userSelector } from "../../../slices/user/userSlice";
import { PickupStatus } from "../../../app/data/common/route";
import boxIcon from "../../../images/box.svg";
import buildingIcon from "../../../images/building.svg";
import calendarIcon from "../../../images/calendar.svg";
import checkIcon from "../../../images/check_gray.svg";
import check2Icon from "../../../images/check_gray-2.svg";
import crossRedIcon from "../../../images/cross_red.svg";
import crossGrayIcon from "../../../images/cross_gray.svg";
import emailIcon from "../../../images/letter_gray.svg";
import imagePreview from "../../../images/image-preview.png";
import newWindow from "../../../images/new-window_gray.svg";
import personIcon from "../../../images/person_blue.svg";
import phoneIcon from "../../../images/phone_gray.svg";
import pointIcon from "../../../images/point.svg";
import clockIcon from "../../../images/clock_blue.svg";
import calendarDotsIcon from "../../../images/calendar-dots_blue.svg";
import infoIcon from "../../../images/info_red.svg";
import { ReactComponent as LinkIcon } from "../../../images/link.svg";
import PickupDetailsBlock from "./pickupDetailsBlock";
import PickupDetailsLog from "./pickupDetailsLog";
import PickupReschedule from "./pickupReschedule";
import PickupCancel from "./pickupCancel";
import { getStatusColor } from "../../../services/pickups";
import PickupComplete from "./pickupComplete";
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";
import { PickupDetailsModel, UpdatePickupModel } from "../../../app/data/pickup/models";

import "./pickupDetails.scss";

export interface PickupDetailsProps {
  pickup: PickupDetailsModel | null;
  onClose: () => void;
  showReschedule?: boolean;
  onUpdatePickup?: (pickup: UpdatePickupModel) => void;
  isDriverLoading?: boolean;
  isOpen: boolean;
  isLoading?: boolean;
  readonly?: boolean;
  onShowPickup?: (pickupNumber: string) => void;
};

const PickupDetails: React.FC<PickupDetailsProps> = ({ isOpen, isLoading, pickup, isDriverLoading, onClose, onUpdatePickup, showReschedule, readonly, onShowPickup }) => {
  const dispatch = useDispatch();
  const userState = useSelector(userSelector);
  const [showReschedulePickup, setShowReschedulePickup] = useState(false);
  const [rescheduledPU, setRescheduledPU] = useState<string | null>(null);
  const [showCancelPickup, setShowCancelPickup] = useState(false);
  const [showCompletePickup, setShowCompletePickup] = useState(false);

  const isAssignedPickup = () => {
    return pickup?.pickupStatus === PickupStatus.ASSIGNED;
  };

  const isClosedPickup = () => {
    return (pickup?.pickupStatus === PickupStatus.COMPLETED) || (pickup?.pickupStatus === PickupStatus.CANCELED);
  };

  const isTodayPickup = () => {
    return moment(pickup?.pickupDate).startOf("day").isSame(moment().startOf("day"));
  };

  const pickupTimeHasCome = () => {
    return !(moment(pickup?.pickupDate).startOf("day").isAfter(moment().startOf("day")));
  };

  const isAtRisk = () => {
    if (isClosedPickup()) return false;
    if (isTodayPickup()) {
      return moment().isAfter(moment(pickup?.pickupCloseTime, "hh:mm:ss").subtract(3, "hours"))
        && moment().isBefore(moment(pickup?.pickupCloseTime, "hh:mm:ss"));
    } else {
      return false;
    }
  };

  const isExpired = () => {
    if (isClosedPickup()) return false;
    let result;
    if (isTodayPickup()) {
      result = moment().isAfter(moment(pickup?.pickupCloseTime, "hh:mm:ss"));
    } else if (moment(pickup?.pickupDate).startOf("day").isBefore(moment().startOf("day"))) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  useEffect(() => {
    setShowCancelPickup(false);
    setShowReschedulePickup(false);
    setShowCompletePickup(false);
    setRescheduledPU(null);
  }, [pickup]);

  useEffect(() => {
    if (!showReschedule) return;
    setShowReschedulePickup(true);
  }, [showReschedule]);

  return (
    <SlideOutSidebar
      header="Pickup Details"
      onClose={onClose}
      show={isOpen}
    >
      <div className="xgs-pickup-details">
        {isLoading && (<div>
          <Loading isLoading />
        </div>
        )}

        {pickup && !isLoading && (
          <>
            <div className="xgs-pickup-details__header">
              <div>
                <div className="xgs-pickup-details__pickup-number">Pickup: {pickup.pickupNumber}</div>
                {pickup.probillNumber && (
                  <div className="xgs-pickup-details__probill-number">
                    <div className="xgs-pickup-details__probill-number__text">Probill: {pickup.probillNumber}</div>
                    <div className="xgs-pickup-details__probill-number__link">
                      <a href={`/shipments/${pickup.probillNumber}`} target="_blank" rel="noreferrer">
                        <img src={newWindow} alt="open in new window" />
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className="xgs-pickup-details__status">Current Status: <Tag mods={{ color: getStatusColor(pickup.pickupStatus) }}>{pickup.pickupStatus}</Tag></div>
            </div>
            <div className="xgs-pickup-details__subheader">
              <div className="xgs-pickup-details__pickup-date">
                {isAtRisk() && <Tag mods={{ color: TagColor.ORANGE, uppercase: true }} mix="xgs-pickup-details__pickup-date__label">AT RISK</Tag>}
                {isExpired() && !pickup.missed && <Tag mods={{ color: TagColor.RED, uppercase: true }} mix="xgs-pickup-details__pickup-date__label">EXPIRED</Tag>}
                {pickup.missed && <div className="xgs-pickup-details__pickup-date__label">MISSED</div>}
                <div className="xgs-pickup-details__pickup-date__icon">
                  <img src={calendarIcon} alt="calendar" />
                </div>
                <div className="xgs-pickup-details__pickup-date__value">
                  {pickup.pickupDate.toUiDateFormat()} {moment(pickup.pickupReadyTime, "hh:mm:ss").format("h:mm A")} - {moment(pickup.pickupCloseTime, "hh:mm:ss").format("h:mm A")}
                </div>
              </div>
              {!readonly && (<div className="xgs-pickup-details__actions">
                {!isClosedPickup() && (
                  <>
                    <div
                      className="xgs-pickup-details__action"
                      onClick={() => setShowReschedulePickup(true)}
                    >
                      <div className="xgs-pickup-details__action__icon">
                        <img src={calendarDotsIcon} alt="reschedule" />
                      </div>
                      <div className="xgs-pickup-details__action__text">
                        Reschedule
                      </div>
                    </div>
                    <ThreeDotsMenu
                      menuItems={[
                        ...((isAssignedPickup() && pickupTimeHasCome())
                          ? [{
                            text: "Complete Pickup",
                            icon: <img src={check2Icon} alt="complete" />,
                            onClick: () => setShowCompletePickup(true)
                          }]
                          : []),
                        {
                          text: "Cancel Pickup",
                          icon: <img src={crossGrayIcon} alt="cancel" />,
                          onClick: () => setShowCancelPickup(true)
                        }
                      ]}
                    />
                  </>
                )}
              </div>
              )}
            </div>
            {rescheduledPU && (
              <div className="xgs-pickup-details__rescheduled">
                <div className="xgs-pickup-details__rescheduled__header"><img src={infoIcon} alt="info" /> Pickup Rescheduled</div>
                <div className="xgs-pickup-details__rescheduled__text">
                  This Pickup was rescheduled to new one. New pickup number is {rescheduledPU}.<br />
                  The customer should have received an email notifying this.
                </div>
                {onShowPickup 
                && <Button
                  className="xgs-pickup-details__rescheduled__button"
                  theme={ButtonThemes.red}
                  size={ButtonSizes.small}
                  onClick={() => onShowPickup?.(rescheduledPU)}
                >
                  Show New Pickup&nbsp;<LinkIcon/>
                </Button>
                }
              </div>
            )}
            {pickup.requester && (
              <div className="xgs-pickup-details__section">
                <div className="xgs-pickup-details__block__content__pair">
                  <div className="xgs-pickup-details__block__content__label">
                    Requester Company:
                  </div>
                  <div className="xgs-pickup-details__block__content__value">
                    {pickup.requester}
                  </div>
                </div>
              </div>
            )}
            <div className="xgs-pickup-details__section">
              <div className="xgs-pickup-details__section__line">
                <div className="xgs-pickup-details__section__icon">
                  <img src={buildingIcon} alt="building" />
                </div>
                <div className="xgs-pickup-details__section__header">
                  Pickup From
                </div>
              </div>
              <div className="xgs-pickup-details__section__content">
                <div className="xgs-pickup-details__section__content__line">
                  <PickupDetailsBlock
                    header="Shipper Location"
                    content={[
                      {
                        label: "Company",
                        value: pickup.shipper.name,
                        required: true,
                      },
                      {
                        label: "Address",
                        value: (pickup.shipper.address.address1 ? pickup.shipper.address.address1.toLowerCase().capitalize() + ", " : "") + (pickup.shipper.address.city ? pickup.shipper.address.city.toLowerCase().capitalize() + ", " : "") + (pickup.shipper.address.state ? pickup.shipper.address.state + ", " : "") + pickup.shipper.address.postalCode,
                        required: true,
                      },
                      {
                        label: "Additional Address Line",
                        value: pickup.shipper.address.additionalAddressLine
                      },
                    ]}
                    oneLine
                  />
                </div>
                <div className="xgs-pickup-details__section__content__line">
                  <PickupDetailsBlock
                    header="Shipper Contact"
                    content={[
                      ...((pickup.shipper.firstName || pickup.shipper.lastName) ? [
                        {
                          label: <div className="xgs-pickup-details__value-icon"><img src={personIcon} alt="person" /></div>,
                          value: (pickup.shipper.firstName || "") + " " + (pickup.shipper.lastName || "")
                        }
                      ] : []),
                      {
                        label: <div className="xgs-pickup-details__value-icon"><img src={phoneIcon} alt="phone" /></div>,
                        value: pickup.shipper.mobilePhone === "0" ? null : pickup.shipper.mobilePhone
                      },
                      ...((pickup.shipper.allowSms !== null) ? [
                        {
                          label: pickup.shipper.allowSms
                            ? <div className="xgs-pickup-details__value-icon"><img src={checkIcon} alt="sms yes" /></div>
                            : <div className="xgs-pickup-details__value-icon"><img src={crossRedIcon} alt="sms no" /></div>,
                          value: pickup.shipper.allowSms ? "SMS allowed" : "SMS not allowed"
                        }
                      ] : []),
                      {
                        label: <div className="xgs-pickup-details__value-icon"><img src={emailIcon} alt="email" /></div>,
                        value: pickup.shipper.email,
                        required: true,
                      },
                    ]}
                    oneLine
                  />
                </div>
              </div>
            </div>
            <div className="xgs-pickup-details__section">
              <div className="xgs-pickup-details__section__line">
                <div className="xgs-pickup-details__section__icon">
                  <img src={pointIcon} alt="point" />
                </div>
                <div className="xgs-pickup-details__section__header">
                  Deliver To
                </div>
              </div>
              <div className="xgs-pickup-details__section__content">
                <PickupDetailsBlock
                  header="Consignee Details"
                  content={[
                    {
                      label: "Company",
                      value: pickup.consignee.name,
                      required: true
                    },
                    {
                      label: "Address",
                      value: (pickup.consignee.address.address1 ? pickup.consignee.address.address1.toLowerCase().capitalize() + ", " : "") + (pickup.consignee.address.city ? pickup.consignee.address.city.toLowerCase().capitalize() + ", " : "") + (pickup.consignee.address.state ? pickup.consignee.address.state + ", " : "") + pickup.consignee.address.postalCode,
                      required: true
                    },
                    {
                      label: "Additional Address Line",
                      value: pickup.consignee.address.additionalAddressLine
                    },
                    {
                      label: "Phone",
                      value: pickup.consignee.phone,
                      required: true
                    },
                    ...(pickup.consignee.email ? [
                      {
                        label: "Email",
                        value: (<a href={`mailto:${pickup.consignee.email}`}>{pickup.consignee.email}</a>)
                      }
                    ] : [])
                  ]}
                  oneLine
                />
              </div>
            </div>
            <div className="xgs-pickup-details__section">
              <div className="xgs-pickup-details__section__line">
                <div className="xgs-pickup-details__section__icon">
                  <img src={calendarIcon} alt="calendar" />
                </div>
                <div className="xgs-pickup-details__section__header">
                  Pickup Info
                </div>
              </div>
              <div className="xgs-pickup-details__section__content">
                <div className="xgs-pickup-details__grid">
                  <div className="xgs-pickup-details__grid__item">
                    <PickupDetailsBlock
                      header="Pickup Details"
                      content={[
                        {
                          label: "Pickup Date",
                          value: (
                            <span style={{ color: (!isTodayPickup() && (isExpired() || isAtRisk())) ? "#C43232" : "inherit" }}>
                              {pickup.pickupDate.toUiDateFormat()}
                            </span>
                          )
                        },
                        {
                          label: "Pickup Time",
                          value: (
                            <span style={{ color: (isExpired() || isAtRisk()) ? "#C43232" : "inherit" }}>
                              {moment(pickup.pickupReadyTime, "hh:mm:ss").format("h:mm A") + " -  " + moment(pickup.pickupCloseTime, "hh:mm:ss").format("h:mm A")}
                            </span>
                          )
                        },
                        ...pickup.driverId ? [{
                          label: "Assigned Driver",
                          value: (
                            <>
                              {isDriverLoading && (
                                <div className="xgs-pickup-details__value-spinner">
                                  <Loading isLoading />
                                </div>
                              )}
                              {pickup.driverName
                                ? (<>{pickup.driverName} <span>({pickup.driverId})</span></>)
                                : pickup.driverId}
                            </>
                          )
                        }] : []
                      ]}
                    />
                  </div>
                  <div className="xgs-pickup-details__grid__item">
                    <PickupDetailsBlock
                      header="Request Details"
                      content={[
                        {
                          label: "Pickup Created",
                          value: pickup.dateCreated?.toUiDateTimeShortFormat()
                        },
                        {
                          label: "Created By",
                          value: pickup.userCreated
                        },
                        {
                          label: "Source",
                          value: pickup.creatorType
                        },
                        {
                          label: "Last Status Update",
                          value: pickup.dateUpdated?.toUiDateTimeShortFormat()
                        },
                        {
                          label: "Updated By",
                          value: pickup.userUpdated
                        }
                      ]}
                    />
                  </div>
                  {pickup.payor.name && (
                    <div className="xgs-pickup-details__grid__item">
                      <PickupDetailsBlock
                        header="Payor Details"
                        content={[
                          {
                            label: "Company",
                            value: pickup.payor.name,
                            required: true,
                          },
                          {
                            label: "Address",
                            value: pickup.payor.address.address1 + ", " + pickup.payor.address.city + ", " + pickup.payor.address.state + ", " + pickup.payor.address.postalCode,
                            required: true,
                          },
                          {
                            label: "Phone",
                            value: pickup.payor.phone,
                            required: true,
                          }
                        ]}
                      />
                    </div>
                  )}
                  {(pickup.bolNumber || pickup.poNumber || pickup.additionalReference) && (
                    <div className="xgs-pickup-details__grid__item">
                      <PickupDetailsBlock
                        header="Additional Information"
                        content={[
                          {
                            label: "BOL #",
                            value: pickup.bolNumber
                          },
                          {
                            label: "PO #",
                            value: pickup.poNumber
                          },
                          {
                            label: "Add. Ref.",
                            value: pickup.additionalReference
                          }
                        ]}
                      />
                    </div>
                  )}
                  {pickup.specialInstructions && (
                    <div className="xgs-pickup-details__grid__item">
                      <PickupDetailsBlock
                        header="Special Instructions"
                        content={[
                          {
                            value: pickup.specialInstructions
                          }
                        ]}
                      />
                    </div>
                  )}
                  {(pickup.documents && pickup.documents.length > 0) && (
                    <div className="xgs-pickup-details__grid__item">
                      <PickupDetailsBlock
                        header="Documents"
                        content={pickup.documents.map((item) => {
                          return {
                            value: (
                              <div className="xgs-pickup-details__document">
                                <a href={item.storageUrl} target="_blank" rel="noreferrer">
                                  <img src={imagePreview} alt="preview" />
                                  {item.type === "BOL_PHOTO" ? "BOL" : "Misc"}
                                </a>
                              </div>
                            )
                          }
                        })}
                        oneLine
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="xgs-pickup-details__section">
              <div className="xgs-pickup-details__section__line">
                <div className="xgs-pickup-details__section__icon">
                  <img src={boxIcon} alt="box" />
                </div>
                <div className="xgs-pickup-details__section__header">
                  Freight Details
                </div>
              </div>
              <div className="xgs-pickup-details__section__content">
                <div className="xgs-pickup-details__items">
                  {pickup.items.map((item, index) => (
                    <div className="xgs-pickup-details__items__row" key={"item-" + index}>
                      <div className="xgs-pickup-details__items__row__line xgs-pickup-details__items__row__line--flex">
                        <Tag mods={{ color: item.packageType === "ROLL" ? TagColor.BLUE : TagColor.PURPLE }} mix="xgs-pickup-details__package-type">
                          {item.packageType}
                        </Tag>
                        <div>
                          <span>Units:</span> {item.units || <span className="xgs-pickup-details__value--missing">missing</span>}
                        </div>
                        {!!(item.yards && item.yards !== 0) && (
                          <div>
                            <span>Sq Yards:</span> {item.yards}
                          </div>
                        )}
                        <div>
                          <span>Pounds:</span> {item.weight || <span className="xgs-pickup-details__value--missing">missing</span>}
                        </div>
                        {!!(item.length || item.width || item.height || item.diameter) && (
                          <>
                            {item.packageType === "PALLET" && (
                              <div>
                                <span>Length / Width / Height:</span> {item.length || "-"} / {item.width || "-"} / {item.height || "-"} in.
                              </div>
                            )}

                            {item.packageType === "ROLL" && (
                              <div>
                                <span>{`${item.length ? "Length / " : ""}Width / Diameter:`}</span> {`${item.length} / ` || ""} {item.width || "-"} / {item.diameter || "-"} in.
                              </div>
                            )}
                          </>
                        )}
                        {item.ratingClass && (
                          <div>
                            <span>Class:</span> {item.ratingClass}
                          </div>
                        )}
                        {item.commodityCodeDescription && (
                          <div>
                            <span>Commodity:</span> {item.commodityCodeDescription}
                          </div>
                        )}
                      </div>
                      {item.notes && (
                        <div className="xgs-pickup-details__items__row__line">
                          <span>Notes:</span> {item.notes}
                        </div>
                      )}
                      {item.sidemark && (
                        <div className="xgs-pickup-details__items__row__line">
                          <span>Sidemark:</span> {item.sidemark}
                        </div>
                      )}
                      {item.description && (
                        <div className="xgs-pickup-details__items__row__line">
                          <span>Description:</span> {item.description}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="xgs-pickup-details__section">
              <div className="xgs-pickup-details__section__line">
                <div className="xgs-pickup-details__section__icon">
                  <img src={clockIcon} alt="clock" />
                </div>
                <div className="xgs-pickup-details__section__header">
                  Activity History
                </div>
              </div>
              <div className="xgs-pickup-details__section__content">
                <PickupDetailsLog
                  pickupId={pickup.pickupId}
                  onRescheduled={(pickup) => {
                    setRescheduledPU(pickup?.pickupNumber || null);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {pickup && (
        <>
          <PickupReschedule
            show={showReschedulePickup}
            pickupId={pickup.pickupId}
            pickupNumber={pickup.pickupNumber}
            shipperZip={pickup.shipper.address.postalCode}
            shipperAccountNumber={pickup.shipper.accountNumber}
            onReschedule={(newPickupId, newPickupNumber, pickupDate) => {
              onUpdatePickup({
                pickupId: pickup.pickupId,
                status: PickupStatus.CANCELED,
                username: userState.profile?.email
              });
              dispatch(getPickupLog(pickup.pickupId));
              setShowReschedulePickup(false);
            }}
            onClose={() => setShowReschedulePickup(false)}
            type="EMPLOYEE"
          />
          <PickupCancel
            show={showCancelPickup}
            pickupId={pickup.pickupId}
            pickupNumber={pickup.pickupNumber}
            shipper={pickup.shipper}
            onCancel={() => {
              onUpdatePickup({
                pickupId: pickup.pickupId,
                status: PickupStatus.CANCELED,
                username: userState.profile?.email
              });
              dispatch(getPickupLog(pickup.pickupId));
              setShowCancelPickup(false);
            }}
            onClose={() => setShowCancelPickup(false)}
            type="EMPLOYEE"
          />
          <PickupComplete
            show={showCompletePickup}
            pickupId={pickup.pickupId}
            pickupNumber={pickup.pickupNumber}
            onComplete={() => {
              onUpdatePickup({
                pickupId: pickup.pickupId,
                status: PickupStatus.COMPLETED,
                username: userState.profile?.email,
                driverId: pickup.driverId,
                manifestNumber: pickup.manifestNumber
              });
              dispatch(getPickupLog(pickup.pickupId));
              setShowCompletePickup(false);
            }}
            onClose={() => setShowCompletePickup(false)}
          />
        </>
      )}
    </SlideOutSidebar>
  );
};

export default PickupDetails;
