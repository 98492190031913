import React, { memo } from "react";

import LabeledInput, {
  CommonLabeledInputProps,
  CommonLabeledInputElementProps,
} from "../labeledInput";
import XGSFieldRange from "../../../xgs-field-range/xgsFieldRange";

export interface LabeledRangeInputProps
  extends CommonLabeledInputElementProps,
    CommonLabeledInputProps {
  className?: string;
  disabled?: boolean;
}

const LabeledRangeInput: React.FC<LabeledRangeInputProps> = memo(({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  meta,
  error,
  className,
  ...props
}) => {
  const isFailed = (): boolean => {
    return (meta?.touched && meta?.error) || error ? true : false;
  };

  return (
    <LabeledInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      isFailed={isFailed}
      error={meta?.error || error}
      className={className}
    >
      <XGSFieldRange>
        {props.children}
      </XGSFieldRange>
    </LabeledInput>
  );
});

export default LabeledRangeInput;
