import React, { ReactElement } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Wrapper } from "@googlemaps/react-wrapper";
import XGSMap from "../../../ui-components/map/map";
import XGSCustomMarker from "../../../ui-components/map/custom-marker/customMarker";
import { PickupColors } from "../../../app/data/common/route";
import Loading from "../../../ui-components/loading/loading";
import "./locationPopup.scss";

export interface LocationPopupProps {
  children: React.ReactElement;
  lat: number;
  lng: number;
  header?: string;
  hideFullscreen?: boolean;
}

const LocationPopup: React.FC<LocationPopupProps> = ({lat, lng, ...props}) => {
  const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_KEY || "";

  const render = (): ReactElement => {
    return <Loading isLoading={true} />;
  };

  return (
    <Popup
      className="xgs-location-popup"
      trigger={props.children}
      position={["top center", "top left"]}
      arrow={false}
      on={["click"]}
      contentStyle={{
        width: "600px",
        height: "480px",
      }}
    >
      <div className="xgs-location-popup__header">{props.header}</div>
      {lat && lng && (
        <div className="xgs-location-popup__map">
          <Wrapper
            apiKey={apiKey}
            render={render}
            libraries={["geometry", "marker"]}
          >
            <XGSMap
              center={{ lat, lng }}
              zoom={14}
              fullscreenControl={!props.hideFullscreen}
            >
              <XGSCustomMarker
                key="location-marker"
                position={{ lat, lng }}
                fillColor={PickupColors.COMPLETED}
              />
            </XGSMap>
          </Wrapper>
        </div>
      )}
    </Popup>
  )
};

export default LocationPopup;