import React, { useEffect, useMemo, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import "./../../../node_modules/react-datepicker/dist/react-datepicker.css";
import "./holidayCalendar.scss";
import { useState } from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import moment from "moment";
import Button, { ButtonSizes, ButtonThemes } from "../../ui-components/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  addHolidayLocally,
  createCustomerHoliday,
  createHoliday,
  getCustomerHolidays,
  getHolidaySuggestions,
  getHolidays,
  holidayCalendarSelector,
  removeCustomerHoliday,
  removeHoliday,
  removeHolidayLocally,
  resetHolidayCalendar,
} from "../../slices/holiday-calendar/holidayCalendarSlice";
import { toast } from "react-toastify";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import { Form, Formik, FormikProps } from "formik";
import { CreateHolidaySchema } from "../../app/data/holiday-calendar/models";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import { HolidayChangeLog } from "./holidayChangeLog";
import ContentContainerToolbar from "../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import { PAGE_IDS } from "../../app/data/common/pageIds";

import styles from "../../sass/variables.module.scss";

const getNextWeekday = () => {
  let nextDate = moment();
  // Loop until you get a day that is not Saturday (6) or Sunday (0)
  while ([6, 0].includes(nextDate.day())) {
    nextDate.add(1, 'day');
  }
  return nextDate.toDate();
};

export const HolidayCalendar: React.FC<{ accountId?: string, isCustomer?: boolean }> = (props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(getNextWeekday());
  const [showLogs, setShowLogs] = useState(false);
  const [name, setName] = useState<string>("");
  const holidayState = useSelector(holidayCalendarSelector);
  const isCustomer = props.isCustomer!;
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<{name: any;}>>(null);

  useEffect(() => {
    dispatch(getHolidaySuggestions());
    if (isCustomer) {
      let accountId: string = props.accountId!;
      dispatch(getCustomerHolidays(accountId));
    } else {
      dispatch(getHolidays());
    }
    return () => {
      dispatch(resetHolidayCalendar());
    };
  }, [dispatch, isCustomer, props.accountId]);

  const getDayClassName = (date: Date) => {
    let className = "";
    if (moment(date).isBefore(moment(), "day")) return className;
    if (
      holidayState.holidaySuggestions.some((curr) =>
        moment(curr.date).isSame(date, "date")
      )
    ) {
      className = "xgs-holiday-calendar__suggestion";
    }
    if (
      holidayState.holidays.some((holiday) => moment(holiday.date).isSame(date, "date"))
    ) {
      className = "xgs-holiday-calendar__holiday";
    }
    return className;
  };

  const [selectedHoliday, selectedDateHolidaySuggestion] = useMemo(() => {
    const holiday = holidayState.holidays.find(({date}) =>
      moment(date).isSame(selectedDate, "date")
    );
    const holidaySuggestion = holidayState.holidaySuggestions.find((current) =>
      moment(current.date).isSame(selectedDate, "date")
    );
    return [holiday, holidaySuggestion?.name];
  }, [selectedDate, holidayState.holidays, holidayState.holidaySuggestions]);
  
  useEffect(() => {
    // If already a set as a holiday find the name or set as empty string for form to fill in
    let holidayName = holidayState.holidays.find(holiday => moment(holiday.date).isSame(selectedDate, "date"))?.name || "";
    setName(holidayName)
    formRef.current?.resetForm()
  }, [selectedDate, selectedHoliday, holidayState.holidays]);

  const onCreateSucess = () => {
    toast.info(isCustomer ? "Exception created" : "Holiday created");
    dispatch(addHolidayLocally({name, date: selectedDate?.toApiFormat()}))
  }
  const onRemoveSucess = () => {
    toast.info(isCustomer ? "Exception removed" : "Holiday removed");
    dispatch(removeHolidayLocally(selectedDate?.toApiFormat()))
  }

  const onFailed = () => {
    toast.error("Something went wrong");
  }
  
  const onCreateHoliday = () => {
    if (!selectedDate) return;
    if (isCustomer) {
      let accountId: string = props.accountId!;
      dispatch(
        createCustomerHoliday(
          accountId,
          selectedDate?.toApiFormat(),
          name,
          onCreateSucess,
          onFailed
        )
      );
    } else {
      dispatch(
        createHoliday(
          selectedDate?.toApiFormat(),
          name,
          onCreateSucess,
          onFailed
        )
      );
    }
  };

  const onRemoveHoliday = () => {
    if (!selectedDate) return;
    if (isCustomer) {
      let accountId: string = props.accountId!;
      dispatch(
        removeCustomerHoliday(
          accountId,
          selectedDate?.toApiFormat(),
          onRemoveSucess,
          onFailed
        )
      );  
    } else {
      dispatch(
        removeHoliday(
          selectedDate?.toApiFormat(),
          onRemoveSucess,
          onFailed
        )
      );
    }
  };

  const onSubmit = () => {
    !selectedHoliday ? onCreateHoliday() : onRemoveHoliday()
  }

  return (
    <ContentContainer
     title=""
     className={isCustomer ? "xgs-holiday-calendar__customer" : ""}
     header={
      <div className="xgs-holiday-calendar__help">
        {!isCustomer && <div className="xgs-holiday-calendar__help__warning">Warning: Changing holiday schedule <strong>affects ETA calculations.</strong></div>}
        <div className="xgs-holiday-calendar__help__legend">
          <div className="xgs-holiday-calendar__help__legend__yellow">
            <XGSIcon icon={XGSIcons.faCircle} color={styles.xgsBlue} />&nbsp;&nbsp;
            {isCustomer ? <>Confirmed exception</> : <>XGS confirmed holiday</>}
          </div>
          <div className="xgs-holiday-calendar__help__legend__red">
            <XGSIcon icon={XGSIcons.faCircle} color={styles.xgsRed}/>&nbsp;
            Recommended U.S. Holiday (not observed by XGS)
          </div>
        </div>
      </div>
     }
     titleComponent={
        <ContentContainerToolbar isLoading={holidayState.loading}  pageId={isCustomer ? "" : PAGE_IDS.HOLIDAYS} title={isCustomer ? "Exception Calendar" : "Holiday Calendar"}>
          <Button className="xgs-holiday-calendar__log-button" onClick={() => setShowLogs(true)}  theme={ButtonThemes.blue}>
            Logs
          </Button>
        </ContentContainerToolbar>
      }
    >
      <div className="xgs-holiday-calendar">
        <div className="xgs-holiday-calendar__flex-container">
          <div className="xgs-holiday-calendar__calendar">
            <ReactDatePicker 
              minDate={moment().toDate()}
              filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
              inline
              selected={selectedDate}
              calendarStartDay={1}
              dayClassName={getDayClassName}
              onChange={(date) => setSelectedDate(date)}
              onDayMouseEnter={(day) => {}}
              onMonthChange={() => setSelectedDate(null)}
              disabledKeyboardNavigation
              disabled={holidayState.loading}
            />
          </div>
          <div className="xgs-holiday-calendar__form">
            <Formik innerRef={formRef} validationSchema={CreateHolidaySchema} initialValues={{name: ""}} onSubmit={onSubmit}>
              {(props) => (
                <Form>
                  <div className="xgs-holiday-calendar__form__name-input">
                    <XGSFormInput
                      name="name"
                      value={name}
                      placeholder={selectedDateHolidaySuggestion}
                      onChange={(e) => {
                        setName(e.currentTarget.value)
                        props.setFieldValue('name', e.currentTarget.value)
                      }} 
                      maxLength={50}
                      label={isCustomer ? "Exception name:" : "Holiday name:"}
                      disabled={!!selectedHoliday}
                      labelMode={LabelModes.column}
                      required
                      requiredAsteriskDisabled={!!selectedHoliday}
                    />
                  </div>
                  <div className="xgs-holiday-calendar__form__buttons">
                    <Button
                      disabled={!!selectedHoliday || holidayState.loading || !selectedDate}
                      type="submit"
                      spinner={
                        holidayState.requestStarted &&
                        holidayState.requestCreator === "CREATE_HOLIDAY"
                      }
                      size={ButtonSizes.auto}
                      theme={ButtonThemes.blue}
                    >
                      {isCustomer ? "Mark Exception" : "Mark Holiday"}
                    </Button>
                    {/* Clear button does not trigger form submit because it does not require holiday name to send request */}
                    <Button
                      disabled={!selectedHoliday || holidayState.loading}
                      type="button"
                      spinner={
                        holidayState.requestStarted &&
                        holidayState.requestCreator === "REMOVE_HOLIDAY"
                      }
                      theme={ButtonThemes.blue}
                      size={ButtonSizes.auto}
                      onClick={onRemoveHoliday}
                    >
                      {isCustomer ? "Clear Exception" : "Clear Holiday"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <HolidayChangeLog isCustomer={isCustomer} accountId={props.accountId} show={showLogs} onClose={() => setShowLogs(false)}/>
    </ContentContainer>
  );
};
