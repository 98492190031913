import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  matchPath,
  NavLink,
  useHistory
} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Location } from "history";
import moment from "moment";
import { cn } from "../../services/common/className";
import { FEATURE, Routes } from "../../app/route/RoutesConfig";
import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";
import { closeMobileSideMenu } from "../../slices/user/userSlice";
import { UserUtils } from "../../app/data/user/userUtils";
import UserService from "../../app/data/user/userService";
import SubAccountSelector from "../sub-account-selector/subAccountSelector";
import TerminalSelector from "../terminal-selector/terminalSelector";
import StageBanner from "../stage-banner/stageBanner";
import FeatureBanner from "../feature-banner/featureBanner";
import NotificationDot from "./notificationDot";
import ContactUs from "../contact-us/contactUs";
import houseIcon from "../../images/house_white.svg";
import truckIcon from "../../images/truck_white.svg";
import boxIcon from "../../images/box_white.svg";
import boxUpIcon from "../../images/box-arrow-up_white.svg";
import paperIcon from "../../images/paper_white.svg";
import papersIcon from "../../images/papers_white.svg";
import talkIcon from "../../images/talk_white.svg";
import chartIcon from "../../images/chart_white.svg";
import warehouseIcon from "../../images/warehouse_white.svg";
import megaphoneIcon from "../../images/megaphone_white.svg";
import cogwheelIcon from "../../images/cogwheel_white.svg";
import helpCenterIcon from "../../images/help-center.svg";
import aiIcon from "../../images/ai_menu.svg";
import folderIcon from "../../images/folder_white.svg";
import linehaulIcon from "../../images/linehaul_white.svg";
import starIcon from "../../images/star_white.svg";
import { QUERY_KEYS } from "../../app/data/common/queryKeys";
import { PAGE_IDS } from "../../app/data/common/pageIds";
import "./menu.scss";

interface MenuItemModel {
  id?: string;
  name: string;
  route?: string;
  action?: () => void;
};

interface MenuModel {
  id: string;
  name: string;
  icon?: any;
  items: MenuItemModel[],
};

const userService = UserService.getInstance();

const FAVORITES_ID = "favorites";

const Menu: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userState: UserState = useSelector(userSelector);
  const [hoverCategoryId, setHoverCategoryId] = useState("");
  const [activeCategoryId, setActiveCategoryId] = useState("");
  const [contactUsPopup, setContactUsPopup] = useState(false);
  const fromFavorites = useRef<boolean>();
  const lastSelectedRoute = useRef<string>();

  const { data: favorites } = useQuery({
    queryKey: [QUERY_KEYS.FAVORITES],
    queryFn: async () => {
      const response = await userService.getFavorites();
      return response.data;
    },
  })

  const isPayor = useMemo(() => (userState.activeSubAccount?.id && userState.profile?.subAccounts)
    ? userState.profile.subAccounts.find(subAccount => subAccount.id === userState.activeSubAccount?.id)?.payor
    : false, [userState.activeSubAccount?.id, userState.profile?.subAccounts]);

  const customerMenuItems: MenuModel[] = useMemo(() => [
    {
      id: "home",
      name: "Home",
      icon: houseIcon,
      items: [
        {
          id: PAGE_IDS.DASHBOARD,
          name: "Dashboard",
          route: Routes.home
        },
        ...(UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "RATE_QUOTE") ? [{
          id: PAGE_IDS.RATE_QUOTES,
          name: "Rate Quotes",
          route: Routes.rates.list
        }] : []),
        {
          id: PAGE_IDS.CLAIMS,
          name: "Claims",
          route: Routes.claims.list
        },
        {
          id: PAGE_IDS.TRANSIT_CALCULATOR,
          name: "Transit Calculator",
          route: Routes.transitCalculator
        }
      ]
    },
    {
      id: "shipments",
      name: "Shipments",
      icon: truckIcon,
      items: [
        {
          id: PAGE_IDS.TRACK_SHIPMENTS,
          name: "Track Shipments",
          route: Routes.shipments.tracking
        },
        {
          id: PAGE_IDS.DOCUMENTS,
          name: "Documents",
          route: Routes.shipments.documents
        }
      ]
    },
    {
      id: "bill-of-lading",
      name: "Bill of Lading",
      icon: boxIcon,
      items: [
        {
          id: PAGE_IDS.BOL,
          name: "BOL",
          route: Routes.bol.list
        },
        ...(!UserUtils.hasFeatureDisabled(userState.profile, FEATURE.RETURNS) ? [{
          id: PAGE_IDS.RETURNS,
          name: "Returns",
          route: Routes.return.list
        }] : []),
        {
          id: PAGE_IDS.PICKUPS,
          name: "Pickups",
          route: Routes.pickups.list,
        }
      ]
    },
    ...(UserUtils.isCustomer(userState.profile) ? [
      {
        id: "warehouse-inventory",
        name: "Warehouse",
        icon: warehouseIcon,
        items: [
          {
            id: PAGE_IDS.WAREHOUSE_SUMMARY,
            name: "Summary",
            route: Routes.warehouseInventory.summary
          },
          {
            id: PAGE_IDS.WAREHOUSE_INVENTORY,
            name: "Inventory",
            route: Routes.warehouseInventory.inventory
          }
        ]
      }
    ]: []),
    ...(UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") ? [{
      id: "aging-invoices",
      name: "Aging Invoices",
      icon: paperIcon,
      items: [
        {
          id: PAGE_IDS.AGING_INVOICES_TOTAL,
          name: "Total",
          route: `${Routes.agingInvoices}/_TOTAL`
        },
        {
          id: PAGE_IDS.AGING_INVOICES_1_30,
          name: "1-30",
          route: `${Routes.agingInvoices}/_1_30`
        },
        {
          id: PAGE_IDS.AGING_INVOICES_31_60,
          name: "31-60",
          route: `${Routes.agingInvoices}/_31_60`
        },
        {
          id: PAGE_IDS.AGING_INVOICES_61_90,
          name: "61-90",
          route: `${Routes.agingInvoices}/_61_90`
        },
        {
          id: PAGE_IDS.AGING_INVOICES_91_120,
          name: "91-120",
          route: `${Routes.agingInvoices}/_91_120`
        },
        {
          id: PAGE_IDS.AGING_INVOICES_120_PLUS,
          name: "120+",
          route: `${Routes.agingInvoices}/_120_PLUS`
        }
      ]
    }] : []),
    ...(isPayor ? [{
      id: "reports",
      name: "Reports",
      icon: chartIcon,
      items: [
        {
          id: PAGE_IDS.REPORTS_SCHEDULED,
          name: "Scheduled",
          route: Routes.reports.scheduled.list
        }
      ]
    }] : []),
    {
      id: "contact-us",
      name: "Contact Us",
      icon: talkIcon,
      items: [        
        {
          name: "Contacts",
          action: () => setContactUsPopup(true)
        }
      ]
    }
  ], [isPayor, userState.activeSubAccount?.id, userState.profile]);

  const employeeMenuItems: MenuModel[] = useMemo(() => [
    {
      id: "ai",
      name: "XGS AI",
      icon: aiIcon,
      items: [
        {
          id: PAGE_IDS.AI,
          name: "Shipments Chat",
          route: Routes.ai.shipmentChat,
        },
      ]
    },
    {
      id: "shipments",
      name: "Shipments",
      icon: truckIcon,
      items: [
        {
          id: PAGE_IDS.TRACK_SHIPMENTS,
          name: "Track Shipments",
          route: Routes.shipments.tracking
        },
        {
          id: PAGE_IDS.APPOINTMENTS,
          name: "Appointments",
          route: Routes.shipments.appointments
        },
        {
          id: PAGE_IDS.IMAGES,
          name: "Images",
          route: Routes.deliveryRecords.list
        },
        {
          id: PAGE_IDS.DOCUMENTS,
          name: "Documents",
          route: Routes.shipments.documents
        }
      ]
    },
    {
      id: "pickups",
      name: "Pickups",
      icon: boxUpIcon,
      items: [
        {
          id: PAGE_IDS.CREATE_PICKUP,
          name: "Create Pickup",
          route: Routes.pickup.create
        },
        {
          id: PAGE_IDS.PICKUP_ASSIGNMENT,          
          name: "Pickup Assignment",
          route: Routes.pickup.management
        },
        {
          id: PAGE_IDS.PICKUP_LOG,
          name: "Pickup Log",
          route: Routes.pickup.log
        }
      ]
    },
    {
      id: "billing",
      name: "Billing",
      icon: papersIcon,
      items: [
        {
          name: "Probill Approval",
          route: Routes.billing.pickupApproval
        }
      ]
    },
    {
      id: "manifests",
      name: "Manifests",
      icon: papersIcon,
      items: [
        {
          id: PAGE_IDS.TRACK_MANIFESTS,
          name: "Track Manifests",
          route: Routes.manifest.manifests,
        },
        {
          id: PAGE_IDS.LINEHAUL_MILEAGE,
          name: "Linehaul Mileage",
          route: Routes.linehaulMileage.mileage
        },
        ...(!UserUtils.hasFeatureDisabled(userState.profile, FEATURE.LINEHAUL_MANAGEMENT) ? [{
          id: PAGE_IDS.LANE_MANAGEMENT,
          name: "Lane Management",
          route: Routes.linehaulMileage.management
        }] : []),
        {
          id: PAGE_IDS.MANIFEST_DOCUMENTS,
          name: "Documents",
          route: Routes.shipments.manifestDocuments
        }
      ]
    },
    {
      id: "dispatch",
      name: "Dispatch",
      icon: warehouseIcon,
      items: [
        {
          id: PAGE_IDS.PLAN_ROUTE,
          name: "Plan Route",
          route: Routes.dispatch.planRoute
        },
        {
          id: PAGE_IDS.ACTIVE_ROUTES,
          name: "Active Routes",
          route: Routes.dispatch.activeRoutes
        },
        {
          id: PAGE_IDS.PICKLISTS,
          name: "Picklists",
          route: Routes.dispatch.picklists
        },
        {
          id: PAGE_IDS.UNPLANNABLE_PROBILLS,
          name: "Unplannable Probills",
          route: Routes.dispatch.unplannableProbills
        },
        {
          id: PAGE_IDS.SERVICE_DISRUPTION,
          name: "Service Disruption",
          route: Routes.dispatch.serviceDisruption
        }
      ]
    },
    {
      id: "linehaul",
      name: "Linehaul",
      icon: linehaulIcon,
      items: [
        {
          id: PAGE_IDS.DOCK_LOADING,
          name: "Dock Loading",
          route: Routes.dockLoading.list
        },
        {
          name: "Outbound Summary",
          route: Routes.pickup.inboundSummary
        },
        {
          name: "Outbound List",
          route: Routes.pickup.inboundPickups
        },
      ]
    },
    {
      id: "documents",
      name: "Documents",
      icon: folderIcon,
      items: [
        {
          id: PAGE_IDS.POD_RECOGNITION,
          name: "POD Recognition",
          route: Routes.documents.podRecognition
        },
        {
          id: PAGE_IDS.RECOGNITION_LOGS,
          name: "Recognition Logs",
          route: Routes.documents.recognitionLogs
        },
        {
          name: "Image Viewer",
          route: Routes.documents.view
        },
        {
          name: "Image Indexer",
          route: Routes.documents.index
        },
        {
          name: "Invoice Generator",
          route: Routes.documents.generate
        },
        ...(!UserUtils.hasFeatureDisabled(userState.profile, FEATURE.REMITTANCE_INVOICES) ? [{
          id: PAGE_IDS.REMITTANCE_INVOICES,
          name: "Remittance Invoices",
          route: Routes.documents.remittanceInvoices
        }] : []),
        {
          name: "Invoices",
          route: Routes.documents.invoices
        }
      ]
    },
    ...((UserUtils.isXGSAdministrator(userState.profile) || !UserUtils.hasFeatureDisabled(userState.profile, FEATURE.ANNOUNCEMENTS)) ? [{
      id: "notices",
      name: "Notices",
      icon: megaphoneIcon,
      items: [
        ...(!UserUtils.hasFeatureDisabled(userState.profile, FEATURE.ANNOUNCEMENTS) ? [{
          id: PAGE_IDS.ANNOUNCEMENT,
          name: "Announcement",
          route: Routes.announcement
        }] : []),
        ...(UserUtils.isXGSAdministrator(userState.profile) ? [{
          id: PAGE_IDS.FEATURE_BANNER,
          name: "Feature Banner",
          route: Routes.featureBanner
        }, {
          id: PAGE_IDS.EMERGENCY_ALERT_MANAGEMENT,
          name: "Emergency Alert",
          route: Routes.emergencyAlertManagement
        }] : [])
      ]
    }] : []),
    {
      id: "administration",
      name: "Administration",
      icon: cogwheelIcon,
      items: [
        {
          id: PAGE_IDS.CUSTOMERS,
          name: "Customers",
          route: Routes.customers.list
        },
        {
          id: PAGE_IDS.AGENTS,
          name: "Agents",
          route: Routes.agents.list
        },
        {
          id: PAGE_IDS.USERS,
          name: "Users",
          route: Routes.users.list
        },
        ...(UserUtils.isXGSAdministrator(userState.profile) ? [
        {id: PAGE_IDS.PENDING_REQUESTS,
          name: "Pending Requests",
          route: Routes.customers.pending,
        },
        {
          id: PAGE_IDS.HOLIDAYS,
          name: "Holidays",
          route: Routes.holidayCalendar
        }] : [])
      ]
    }
  ], [userState.profile]);  

  const mainMenuItems = useMemo(() => UserUtils.isCustomer(userState.profile) ? customerMenuItems : (UserUtils.isEmployee(userState.profile) ? employeeMenuItems : []), [
    userState.profile, customerMenuItems, employeeMenuItems
  ]); 

  const favoritesMenuItems: MenuModel[] = useMemo(() => {
    if (!favorites) return [];
    const subitems = mainMenuItems.reduce((result: MenuItemModel[], menuItem) => [...result, ...menuItem.items], []);
    const favoritesSubitems = favorites
    .sort((item1, item2) => moment(item1.dateCreated).isBefore(moment(item2.dateCreated)) ? -1 : 1)
    .reduce((result: MenuItemModel[], page) => {
      const subitem = subitems.find(subitem => subitem.id === page.favoritePageId )
      return subitem ? [...result, subitem] : result;
    }, []);
    return favoritesSubitems.length ? [{
      id: FAVORITES_ID,
      name: "Favorites",
      icon: starIcon,
      items: favoritesSubitems,
    }] : [];
  }, [mainMenuItems, favorites]);

  const menuItems = useMemo(() => [
    ...favoritesMenuItems,
    ...mainMenuItems,
  ], [favoritesMenuItems, mainMenuItems]);

  const itemRouteMatchTheCurrentPath = (
    route: string,
    match: any,
    location: Location
  ): boolean => {
    if (!route) return false;
    if (match?.isExact) return true;
    route = route?.toLowerCase();
    let path = location.pathname.toLowerCase() + (location.search || "");
    path = path.replace(/^\/\d+/gm, "");
    if (path === "") path = "/";
    if (path === route) return true;
    if (route !== "/") {
      path = path.substring(0, route.length);
      return path === route;
    } else {
      // if ((path.indexOf("/aging-invoices") !== -1) || (path.indexOf("/invoices/") !== -1)) {
      //   // custom logic for the route that isn't part of the parent route
      //   return true;
      // }
      return false;
    }
  };

  const getActiveCategory = useCallback((menuItems: MenuModel[], location: Location) => {
    for (const category of menuItems) {
      const currentCategory = category.items.find(menuItem =>
        itemRouteMatchTheCurrentPath(
          menuItem.route,
          undefined,
          location
        ) ||
        matchPath(location.pathname, {
          path: menuItem.route,
          exact: true,
          strict: false
        }) ||
        matchPath(location.pathname, {
          path: `/:number${menuItem.route}`,
          exact: true,
          strict: false
        })
      );
      if (currentCategory) {
        return category;
      }
    }
  }, [])

  const setActiveCategory = useCallback((location: any) => {
    if (lastSelectedRoute.current && !itemRouteMatchTheCurrentPath(
      lastSelectedRoute.current,
      undefined,
      location
    )) {
      fromFavorites.current = false;
    }

    if (fromFavorites.current) {
      const category = getActiveCategory(favoritesMenuItems, location);
      if (category) {
        setActiveCategoryId(category.id);
        setHoverCategoryId(category.id);
        return;
      }
    };

    const category = getActiveCategory(mainMenuItems, location);
    if (category) {
      setActiveCategoryId(category.id);
      setHoverCategoryId(category.id);
    }
  }, [favoritesMenuItems, mainMenuItems, getActiveCategory]);

  useEffect(() => {
    if (activeCategoryId) return;
    let categoryId;
    for (const category of menuItems) {
      const currentCategory = category.items.find(menuItem =>
        itemRouteMatchTheCurrentPath(
          menuItem.route,
          undefined,
          window.location
        )
      );
      if (currentCategory) {
        categoryId = category.id;
        setActiveCategoryId(category.id);
        setHoverCategoryId(category.id);
        break;
      }
    }
    if (!categoryId) {
      // case for pages that do not have an item in the main menu (e.g. User Settings)
      const defaultCategoryId = UserUtils.isCustomer(userState.profile) ? "home" : "shipments";
      setActiveCategoryId(defaultCategoryId);
      setHoverCategoryId(defaultCategoryId);
    }
    // eslint-disable-next-line
  }, [menuItems, activeCategoryId]);

  useEffect(() => {
    history.listen((location) => {
      setActiveCategory(location);
    });
    // eslint-disable-next-line
  }, [history, menuItems]);

  useEffect(() => {
    setActiveCategory(window.location);
  }, [setActiveCategory]);

  useEffect(() => {
    // we have no dashboard for employees, so redirect employees to Track Shipments page
    if (UserUtils.isEmployee(userState.profile) && window.location.pathname === "/") {
      window.location.replace(Routes.shipments.tracking);
    }
  }, [userState.profile]);

  return (
    <div className={`xgs-site__menu-wrapper${UserUtils.isXGSDriver(userState.profile) ? " display-none" : ""}`}>
      <div
        className="xgs-menu"
        onMouseLeave={() => setHoverCategoryId(activeCategoryId)}
      >
        <div className="xgs-menu__top-level">
          {menuItems.map((category, i) => (
            <div
              className={cn("xgs-menu__top-level__item")({
                active: activeCategoryId === category.id,
                hover: hoverCategoryId === category.id
              })}
              onMouseEnter={() => setHoverCategoryId(category.id)}
              key={`top-level-${i}`}
            >
              <img src={category.icon} alt={category.name} />
            </div>
          ))}
        </div>
        <div className="xgs-menu__second-level">
          {menuItems.filter((category) => category.id === hoverCategoryId).map((category, ci) => (
            <React.Fragment key={`second-level-${ci}`}>
              <div className="xgs-menu__second-level__header">{category.name}</div>
              {category.items.map((item, ii) => (
                <React.Fragment key={`second-level-item-${ii}`}>
                  {item.route && (
                    UserUtils.isEmployee(userState.profile)
                    || UserUtils.isAdministrator(userState.profile)
                    || item.route === "/"                    
                    || (userState.profile?.subAccounts && userState.profile.subAccounts.length > 0)) ? (
                    <NavLink
                      to={item.route}
                      className="xgs-menu__second-level__item"
                      activeClassName="xgs-menu__second-level__item--active"
                      isActive={(match, location) => itemRouteMatchTheCurrentPath(item.route, match, location)}
                      onClick={() => {
                        dispatch(closeMobileSideMenu());
                        fromFavorites.current = (category.id === FAVORITES_ID);
                        lastSelectedRoute.current = item.route;
                      }}
                    >
                      <div>{item.name}<NotificationDot id={item.id} /></div>
                      <div className="xgs-menu__second-level__item__arrow">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.70857 0L0.5 1.20857L5.29143 6L0.5 10.7914L1.70857 12L7.70857 6L1.70857 0Z" fill="#2F80ED" />
                        </svg>
                      </div>
                    </NavLink>
                  ) : (
                    <div className="xgs-menu__second-level__item">
                      <span onClick={() => item.action && item.action()}>{item.name}</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          <div className="xgs-menu__second-level__bottom-block">
            {UserUtils.isEmployee(userState.profile) && <FeatureBanner employee={true} />}
            <StageBanner />
            {UserUtils.isCustomer(userState.profile) && (
              <a href="https://support.xgsi.com/knowledge" target="_blank" rel="noopener noreferrer" className="xgs-menu__knowledge-base__link">
                <div className="xgs-menu__knowledge-base__block">
                  <img src={helpCenterIcon} className="xgs-menu__knowledge-base__icon" alt="Knowledge base" /> Help Center
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="xgs-mobile-menu">
        <StageBanner />
        {UserUtils.isEmployee(userState.profile) && <FeatureBanner employee={true} />}
        <div className="xgs-mobile-menu__selector">
          {UserUtils.isCustomer(userState.profile) && <SubAccountSelector />}
          {UserUtils.isEmployee(userState.profile) && <TerminalSelector />}
        </div>
        <div className="xgs-mobile-menu__items">
          {menuItems.map((category, ci) => (
            <React.Fragment key={`mobile-categories-${ci}`}>
              <div className="xgs-mobile-menu__items__top-level-item">
                <img src={category.icon} alt={category.name} /> {category.name}
              </div>
              {category.items.map((item, ii) => (
                <React.Fragment key={`mobile-items-${ii}`}>
                  {item.route && (
                    UserUtils.isXGSUser(userState.profile)
                    || UserUtils.isXGSAdministrator(userState.profile)
                    || UserUtils.isAdministrator(userState.profile)
                    || item.route === "/"
                    || (userState.profile?.subAccounts && userState.profile.subAccounts.length > 0)) ? (
                    <NavLink
                      to={item.route}
                      className="xgs-mobile-menu__items__second-level-item"
                      activeClassName="xgs-mobile-menu__items__second-level-item--active"
                      isActive={(match, location) => itemRouteMatchTheCurrentPath(item.route, match, location)}
                      onClick={() => {
                        dispatch(closeMobileSideMenu());
                        fromFavorites.current = (category.id === FAVORITES_ID);
                        lastSelectedRoute.current = item.route;
                      }}
                    >
                      <div>{item.name}<NotificationDot id={item.id} /></div>
                    </NavLink>
                  ) : (
                    <div className={`xgs-mobile-menu__items__second-level-item ${item.action ? "cursor-pointer" : ""}`}>
                      <span onClick={() => item.action && item.action()}>{item.name}</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          <div className="xgs-mobile-menu__second-level__bottom-block">
            {UserUtils.isCustomer(userState.profile) && (
              <a href="https://support.xgsi.com/knowledge" target="_blank" rel="noopener noreferrer" className="xgs-menu__knowledge-base__link">
                <div className="xgs-menu__knowledge-base__block">
                  <img src={helpCenterIcon} className="xgs-menu__knowledge-base__icon" alt="Knowledge base" /> Help Center
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
      <ContactUs
        onClose={() => setContactUsPopup(false)}
        showPopup={contactUsPopup}
      />
    </div>
  );
};

export default Menu;
