import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { createColumnHelper } from "@tanstack/react-table";

import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
// import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import Table from "../../../ui-components/table/table";
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";
import NumberFormat from "react-number-format";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import InvoicesSummary from "./invoicesSummary";
import InvoicesSelectedBar from "./invoicesSelectedBar";

import { /*InvoiceDeliveryStatus,*/ InvoicePaymentStatus, InvoiceResponseModel, InvoicesFilterModel, InvoicesFilterSchema } from "../../../app/data/documents/models";
// import { baseAgingPeriods } from "../../../app/data/aging/models";
import { getInvoices, getInvoicesSummary, invoicesSelector } from "../../../slices/documents/invoicesSlice";
import { SortOrder, SortParams } from "../../../app/data/common/models";

import "./invoices.scss";

const initialFilterValues: InvoicesFilterModel = {
  probill: undefined,
  customer: "",
  fromDate: undefined,
  toDate: undefined,
  missed: undefined,
  paymentStatus: undefined,
};

// const missedOptions = [
//   { label: "Yes", value: "yes" },
//   { label: "No", value: "no" },
// ];

// const missingPeriodOptions = Object.values(baseAgingPeriods).map(period => ({ label: period.title, value: period.period }));

const columnHelper = createColumnHelper<InvoiceResponseModel>();

const Invoices: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const invoicesState = useSelector(invoicesSelector);
  const filterFormRef = useRef<FormikProps<InvoicesFilterModel>>(null);
  const filterRef = useRef<InvoicesFilterModel>(initialFilterValues);
  const sortingRef = useRef<SortParams | null>(null);

  const getPaymentStatusColor = (status: InvoicePaymentStatus) => {
    switch (status) {
      case InvoicePaymentStatus.paid: return TagColor.GREEN;
      case InvoicePaymentStatus.pending: return TagColor.ORANGE;
      case InvoicePaymentStatus.overdue: return TagColor.RED;
    }
  };

  // const getDeliveryStatus = (row: InvoiceResponseModel) => {
  //   if (row.status.delivered) {
  //     return InvoiceDeliveryStatus.delivered;
  //   } else {
  //     return InvoiceDeliveryStatus.pending;
  //   }
  // }

  // const getDeliveryStatusColor = (status: InvoiceDeliveryStatus) => {
  //   switch (status) {
  //     case InvoiceDeliveryStatus.delivered: return TagColor.GREEN;
  //     case InvoiceDeliveryStatus.pending: return TagColor.ORANGE;
  //   }
  // };

  const columns = [
    columnHelper.accessor("invoiceNumber", {
      header: "Invoice #",
      cell: (cellProps) => (
        <span
          // className="xgs-table-link-cell"
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
        >
          {cellProps.getValue()}
        </span>
      ),
      enableSorting: false,
      size: 112,
    }),
    columnHelper.accessor("payor.info", {      
      header: "Customer Name",
      enableSorting: false,
      size: 205,
    }),
    // columnHelper.accessor("deliveryApproach", {
    //   header: "Delivery Approach",
    //   size: 100,
    // }),
    // columnHelper.accessor(getDeliveryStatus, {
    //   header: "Delivery Status",
    //   cell: (cellProps) => (
    //     <Tag mods={{ color: getDeliveryStatusColor(cellProps.getValue()), uppercase: true }}>
    //       {cellProps.getValue()}
    //     </Tag>
    //   ),
    //   size: 135,
    // }),
    columnHelper.accessor("invoice.dueDate", {
      id: "dueDate",     
      header: "Due Date",
      cell: (cellProps) => (cellProps.getValue()?.toUiDateFormat() || ""),
      size: 200,
    }),
    columnHelper.accessor("invoice.paymentDate", {
      id: "paymentDate",     
      header: "Payment Date",
      cell: (cellProps) => (cellProps.getValue()?.toUiDateFormat() || ""),
      size: 200,
    }),
    columnHelper.accessor("invoice.invoiceAmount", {
      id: "amount",
      header: "Amount",
      cell: (cellProps) => (
        <div className="xgs-table__cell-amount">
          <span>$</span>
          <span className="xgs-table__cell-amount__value">
            <NumberFormat
              value={cellProps.getValue()}
              decimalScale={2}
              displayType={"text"}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </span>
        </div>
      ),
      size: 104,
    }),
    columnHelper.accessor("newStatus", {
      id: "paymentStatus",  
      header: "Payment Status",
      cell: (cellProps) => (
        <Tag mods={{ color: getPaymentStatusColor(cellProps.getValue()), uppercase: true }}>
          {cellProps.getValue()}
        </Tag>
      ),
      size: 135,
    }),
  ];

  let clearSelection: (() => void) | null = null;
  let clearSorting: (() => void) | null = null;

  const assignClearSelection = (f: () => void) => {
    clearSelection = f;
  };

  const assignClearSorting = (f: () => void) => {
    clearSorting = f;
  };

  const onSubmitFilter = (values: InvoicesFilterModel) => {
    const filterValues = {
      ...values,
      ...values.fromDate && { fromDate: values.fromDate.toApiDateFormat()},
      ...values.toDate && { toDate: values.toDate.toApiDateFormat()},
      ...values.missed && { missed: (values.missed === "yes") },
    };
    
    filterRef.current = filterValues;
    sortingRef.current = null;
    dispatch(getInvoices(filterValues, null));
    clearSelection?.();
    clearSorting?.();
  };

  const onListInfiniteScroll = () => {
    if (invoicesState.invoicesRequest?.loadedAll) return;
    dispatch(getInvoices(filterRef.current, sortingRef.current, invoicesState.invoicesRequest?.lastIds));
  };

  const onSortByColumn = (index: number, order: SortOrder) => {
    const sortBy = columns[index].id;

    if (!sortBy) return;

    const sortParams = order ? { sortBy, order } : {}

    sortingRef.current = sortParams;
    dispatch(getInvoices(filterRef.current, sortParams));
    clearSelection?.();
  }

  const renderInvoicesBar = (rows: {original: InvoiceResponseModel}[], toggleAllRowsSelected: (selected: boolean) => void) => {
    const invoiceNumbers = rows.map(row => row.original.invoiceNumber);
    return <InvoicesSelectedBar selectedInvoices={invoiceNumbers} onReset={() => {toggleAllRowsSelected(false)}}/>
  }

  useEffect(() => {
    dispatch(getInvoices(initialFilterValues, sortingRef.current));
    dispatch(getInvoicesSummary());
  }, [dispatch]);

  return (
    <ContentContainer
      className="xgs-list xgs-list--full-width"
      mods={{ "full-width": true }}
      titleComponent={
        <ContentContainerToolbar
          title="Invoices"
          mods={{ "full-width": true }}
        />
      }
    >
      <div className="xgs-documents__invoices">
        <div className="xgs-documents__invoices__controls">
          <div className="xgs-list__controls">
            <div className="xgs-list__controls__search">
              <Formik
                initialValues={initialFilterValues}
                onSubmit={onSubmitFilter}
                innerRef={filterFormRef}
                validationSchema={InvoicesFilterSchema}
              >
                {(props: FormikProps<InvoicesFilterModel>) => (
                  <Form className="xgs-list__controls__search-form">
                    <XGSFormInput
                      type="text"
                      name="probill"
                      label="Probill #"
                      labelMode={LabelModes.column}
                      className="xgs-item-form__field"
                    />
                    <XGSFormInput
                      type="text"
                      name="customer"
                      label="Customer"
                      labelMode={LabelModes.column}
                      placeholder="Name or Number"
                      className="xgs-item-form__field"
                    />
                    {/* <LabeledDateRangeInput
                    label="Due Date Range"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field xgs-list__controls__date-range"
                    start={props.values.fromDate}
                    end={props.values.toDate}
                    onStartChange={(v) => {
                      props.setFieldValue("fromDate", v || undefined);
                    }}
                    onEndChange={(v) => {
                      props.setFieldValue("toDate", v || undefined);
                    }}
                  />
                  <XGSFormSelect
                    name="deliveryApproach"
                    label="Delivery Approach"
                    labelMode={LabelModes.column}
                    placeholder="All types"
                    className="xgs-item-form__field"
                    options={[]}
                    isClearable
                    controlled={true}
                  />
                  <XGSFormSelect
                    name="missed"
                    label="Missed only"
                    labelMode={LabelModes.column}
                    placeholder="No"
                    className="xgs-item-form__field"
                    options={missedOptions}
                    isClearable
                    controlled={true}
                    onValueChange={(v) => {
                      if (v?.value === "yes") return;
                      props.setFieldValue("missedPeriod", undefined);
                    }}
                  />
                  <XGSFormSelect
                    name="missedPeriod"
                    label="Missing Period"
                    labelMode={LabelModes.column}
                    placeholder="All time"
                    className="xgs-item-form__field"
                    options={missingPeriodOptions}
                    isClearable
                    controlled={true}
                    disabled={props.values.missed !== "yes"}
                  /> */}
                  <XGSFormSelect
                    name="paymentStatus"
                    label="Payment Status"
                    labelMode={LabelModes.column}
                    placeholder="All"
                    className="xgs-item-form__field"
                    options={Object.values(InvoicePaymentStatus).map((value) => ({ value: value, label: value.toLowerCase().capitalize()}))}
                    isClearable
                    controlled={true}
                  />
                    <Button
                      type="submit"
                      theme={ButtonThemes.blue}
                      className=""
                      spinner={false}
                    >
                      Search
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div className="xgs-documents__invoices__summary">
            <InvoicesSummary />
          </div>
        </div>

        <div className="xgs-documents__invoices__list">
          {(invoicesState.request["GET_INVOICES"]?.requestFailed) && (
            <XGSErrorMessage>{invoicesState.request["GET_INVOICES"]?.requestError}</XGSErrorMessage>
          )}
          {!(invoicesState.request["GET_INVOICES"]?.requestFailed) && (
            <Table
              isLoading={invoicesState.request["GET_INVOICES"]?.requestStarted}
              keepTableOnLoading
              columns={columns}
              data={invoicesState.invoices}
              rowHeight={56}
              minTableHeight={340}
              noResultsText="There are no records"
              responsive
              infiniteScroll
              infiniteScrollLoading={invoicesState.request["GET_INVOICES_PORTION"]?.requestStarted}
              infiniteScrollHasNext={!invoicesState.invoicesRequest?.loadedAll}
              onInfiniteScroll={onListInfiniteScroll}
              sorting={true}
              onSortByColumn={onSortByColumn}
              enableRowSelection={true}
              renderSelectedBar={renderInvoicesBar}
              clearSelection={assignClearSelection}
              clearSorting={assignClearSorting}
            />
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default Invoices;