import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import NumberFormat from "react-number-format";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import { RemittanceInvoiceModel } from "../../../app/data/documents/models";
import Table from "../../../ui-components/table/table";
import { ReactComponent as WarningIcon } from "../../../images/warning.svg";

export interface AdviceDetailsProps {
  invoices: RemittanceInvoiceModel[] | null;
  customer: string | null;
  onClose: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  isError?: boolean;
};

const columnHelper = createColumnHelper<RemittanceInvoiceModel>();

const AdviceDetails: React.FC<AdviceDetailsProps> = ({ isOpen, isLoading, isError, invoices, customer, onClose }) => {

  const columns = [
    columnHelper.accessor("invoiceNumber", {
      id: "invoiceNumber",
      header: "Invoice #",
      size: 200,
    }),
    columnHelper.accessor("amountPaid", {
      id: "amountPaid",
      header: "Amount Paid",
      cell: (cellProps) => (<div className="xgs-table__cell-amount">
        <span>$&nbsp;</span>
        <span className="xgs-table__cell-amount__value">
          <NumberFormat
            value={cellProps.getValue() || 0}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        </span>
      </div>),
      size: 200,
    }),
  ];

  return (
    <SlideOutSidebar
      header="Invoice Details"
      onClose={onClose}
      show={isOpen}
    >
      <div className="remittance-invoices__details">
        <div className="remittance-invoices__details__header">{customer || "-"}</div>

        {(isError || (!isLoading && !invoices?.length)) ? (
          <div className="remittance-invoices__details__error">
            <div className="remittance-invoices__details__error__title"> <WarningIcon /> Error </div>
            <div>No invoices information found.</div>
          </div>
        ) : (
          <Table
            isLoading={isLoading}
            columns={columns}
            data={invoices || []}
            rowHeight={56}
            minTableHeight={240}
            noResultsText="There are no records"
            responsive
          />
        )}
      </div>
    </SlideOutSidebar>
  );
};

export default AdviceDetails;
