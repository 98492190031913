import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import sortBy from "lodash/sortBy";
import { createColumnHelper } from "@tanstack/react-table";
import { PathParams, PickupLogPath } from "./route";
import { Routes } from "../../../app/route/RoutesConfig";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import Table from "../../../ui-components/table/table";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import ContentContainer from "../../../templates/content-container/contentContainer";
import { userSelector } from "../../../slices/user/userSlice";
import {
  clearPickupsLog,
  getPickupsLog,
  pickupAssignmentSelector
} from "../../../slices/pickup/pickupAssignmentSlice";
import {
  PickupsLogFilterModel,
  PickupsLogFilterSchema
} from "../../../app/data/pickup/models";
import { HUMAN_FRIENDLY_ACTIONS } from "../common";
import PickupDetails from "../../common/pickup-details/pickupDetails";
import { getPickup, pickupDetailsSelector } from "../../../slices/pickup/pickupDetailsSlice";
import { getPickupDetails } from "../../../services/pickups";
import { PAGE_IDS } from "../../../app/data/common/pageIds";
import LocationPopup from "../../common/location-popup/locationPopup";
import { ReactComponent as PointIcon } from "../../../images/point.svg";
import "./index.scss";

const initialValues = {
  actionPerformed: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  userEmail: "",
  pickupNumber: ""
};

const columnHelper = createColumnHelper<any>();

const PickupLog: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const params = useParams() as PathParams;
  const userState = useSelector(userSelector);
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);
  const pickupDetailsState = useSelector(pickupDetailsSelector);
  const filterFormRef = useRef<FormikProps<PickupsLogFilterModel>>(null);
  const [filter, setFilter] = useState<PickupsLogFilterModel>(initialValues);
  const [pickupDetailsNumber, setPickupDetailsNumber] = useState<string>("");
  const pickupNumber = useMemo(() => params[PickupLogPath.pickupNumber], [params]);
  const pickupDetails = pickupDetailsState.pickup && getPickupDetails(pickupDetailsState.pickup);

  const columns = [
    columnHelper.accessor("actionExecutedTime", {
      id: "actionExecutedTime",
      header: "Event Time",
      cell: (cellProps) => (
        <>
          {cellProps.getValue() && (
            <div className="xgs-pickup-details__log__date">
              <div>{cellProps.getValue().toUiDateFormat()}</div> <div className="xgs-pickup-details__log__gray-value">{cellProps.getValue().toUiTimeShortFormat()}</div>
            </div>
          )}
        </>
      ),
      size: 148,
    }),
    columnHelper.accessor("pickupNumber", {
      id: "pickupNumber",
      header: "Pickup #",
      cell: (cellProps) => (
        <span
          className="xgs-table-link-cell"
          onClick={(e) => {
            e.stopPropagation();
            onShowPickupDetails(cellProps.getValue());
          }}
        >
          {cellProps.getValue()}
        </span>
      ),
      size: 100,
    }),
    columnHelper.accessor("userExecuted", {
      id: "userExecuted",
      header: "User",
      size: 245,
    }),
    columnHelper.accessor("actionPerformed", {
      id: "actionPerformed",
      header: "Action",
      cell: (cellProps) => (
        <>
          {!!cellProps.getValue() &&
            (HUMAN_FRIENDLY_ACTIONS[cellProps.getValue() as keyof typeof HUMAN_FRIENDLY_ACTIONS]
              ? HUMAN_FRIENDLY_ACTIONS[cellProps.getValue() as keyof typeof HUMAN_FRIENDLY_ACTIONS]
              : cellProps.getValue())
          }
        </>
      ),
      size: 160,
    }),
    columnHelper.display({
      id: "additionalInfo",
      header: "Additional Info",
      cell: ({ row }) => (
        <div className="xgs-pickup-log__additional-info" title={row.original.notes || ""}>
          {(row.original.actionPerformed === "SAVE_PICKUP_ARRIVAL_INFO") && (
            <div>
              {(!row.original.pickupArrivalLatitude || !row.original.pickupArrivalLongitude) && (
                <>pickup location (coordinates not provided)</>
              )}
              {!!row.original.pickupArrivalLatitude && !!row.original.pickupArrivalLongitude && (
                <LocationPopup
                  lat={row.original.pickupArrivalLatitude}
                  lng={row.original.pickupArrivalLongitude}
                  header="Pickup Location"
                >
                  <div className="xgs-pickup-log__additional-info__v-centered-item"><PointIcon /> <span className="blue-link">pickup location</span></div>
                </LocationPopup>
              )}
            </div>
          )}
          {!!row.original.notes && (
            <span>
             {row.original.notes}
            </span>
          )}
          {!!row.original.driverName && (
            <span>
             {row.original.driverName} {row.original.driverId ? (<span className="xgs-pickup-details__log__gray-value">({row.original.driverId})</span>) : ""}
            </span>
          )}
          {!row.original.driverName && !!row.original.driverId && (
            <span>
             {row.original.driverId}
            </span>
          )}
          {!row.original.driverName && !!row.original.driverId && (
            <span>
             {row.original.driverId}
            </span>
          )}
        </div>
      ),
      size: 340,
    })
  ];

  const getActionPerformedValues = () => {
    const options = Object.keys(HUMAN_FRIENDLY_ACTIONS).map((key: string) => {
      return {
        value: key,
        label: HUMAN_FRIENDLY_ACTIONS[key as keyof typeof HUMAN_FRIENDLY_ACTIONS]
      };
    });
    return sortBy(options, ["label"]);
  };

  const onListInfiniteScroll = () => {
    if (pickupAssignmentState.pickupsLogRequest.last) return;
    const nextPage = pickupAssignmentState.pickupsLogRequest.page + 1;
    dispatch(getPickupsLog(filter, nextPage));
  };

  const onFilterSubmit = (data: PickupsLogFilterModel) => {
    if (data.dateFrom) data.dateFrom = data.dateFrom.toApiDateFormat();
    if (data.dateTo) data.dateTo = data.dateTo.toApiDateFormat();
    if (data.pickupNumber !== pickupNumber)
      window.history.replaceState(window.history.state, "", `${userState.activeTerminal?.id ? "/" + userState.activeTerminal?.id : ""}${Routes.pickup.log}`);
    setFilter(data);
    setPickupDetailsNumber("");
    dispatch(getPickupsLog(data));
  };

  const onShowPickupDetails = useCallback((pickupNumber: string) => {
    if (pickupDetailsNumber !== pickupNumber) {
      setPickupDetailsNumber(pickupNumber);
      dispatch(getPickup(pickupNumber));
    }
  // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPickupsLog(pickupNumber ? {pickupNumber} : {}));
    if (pickupNumber) {
      setFilter({pickupNumber});
      filterFormRef.current?.setFieldValue("pickupNumber", pickupNumber);
      onShowPickupDetails(pickupNumber);
    }
    return () => {
      dispatch(clearPickupsLog());
    }
  }, [dispatch, pickupNumber, onShowPickupDetails]);

  return (
    <ContentContainer pageId={PAGE_IDS.PICKUP_LOG} title="Pickup Log" mods={{ "full-width": true }}>
      <div className="xgs-pickup-log">
        <div className="xgs-list__controls">
          <Formik
            initialValues={initialValues}
            onSubmit={onFilterSubmit}
            validationSchema={PickupsLogFilterSchema}
            innerRef={filterFormRef}
          >
             {(props: FormikProps<PickupsLogFilterModel>) => (
              <Form>
                <div className="xgs-list__controls__search">
                  <XGSFormSelect
                    name="actionPerformed"
                    label="Performed Action"
                    labelMode={LabelModes.column}
                    placeholder="Select..."
                    options={getActionPerformedValues()}
                    onValueChange={(o) => {
                      props.setFieldValue("actionPerformed", o?.value || undefined);
                      if (
                        (pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG"))
                        || !props.isValid
                      ) return;
                      onFilterSubmit({...props.values, actionPerformed: o?.value.toString() })
                    }}
                    className="xgs-list__controls__search__input"
                    isClearable
                  />
                  <LabeledDateRangeInput
                    label="Dates range:"
                    labelMode={LabelModes.column}
                    className="xgs-list__controls__date-range"
                    start={props.values.dateFrom}
                    end={props.values.dateTo}
                    maxDate={new Date(moment().format("MM/DD/YYYY"))}
                    onStartChange={(v) => {
                      props.setFieldValue("dateFrom", v || undefined);
                      props.submitForm();
                    }}
                    onEndChange={(v) => {
                      props.setFieldValue("dateTo", v || undefined);
                      props.submitForm();
                    }}
                  />
                  <XGSFormInput
                    label="User:"
                    name="userEmail"
                    labelMode={LabelModes.column}
                    onChange={(e) => {
                      props.setFieldValue("userEmail", e.currentTarget.value);
                      props.handleChange(e);
                    }}
                    onKeyDown={(e) => {
                      props.setFieldValue("userEmail", e.currentTarget.value || undefined);
                      if (pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG")) return;
                      if (e.key === "Enter" && props.isValid) onFilterSubmit({...props.values, userEmail: e.currentTarget.value});
                      props.handleChange(e);
                    }}
                    type="text"
                    placeholder="someone@xgsi.com"
                    className="xgs-list__controls__search__input xgs-list__controls__search__input--narrow"
                  />
                  <XGSFormInput
                    label="Pickup #:"
                    labelMode={LabelModes.column}
                    name="pickupNumber"
                    onChange={(e) => {
                      props.setFieldValue("pickupNumber", e.currentTarget.value);
                      props.handleChange(e);
                    }}
                    onKeyDown={(e) => {
                      props.setFieldValue("pickupNumber", e.currentTarget.value || undefined);
                      if (pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG")) return;
                      if (e.key === "Enter" && props.isValid) onFilterSubmit({...props.values, pickupNumber: e.currentTarget.value});
                      props.handleChange(e);
                    }}
                    type="text"
                    placeholder="e.g. PU00012345"
                    className="xgs-list__controls__search__input xgs-list__controls__search__input--narrow"
                  />
                  <Button
                    type="submit"
                    theme={ButtonThemes.blue}
                    disabled={
                      (pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG"))
                    }
                    className="xgs-list__controls__button"
                  >
                    Search
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {!pickupAssignmentState.requestFailed && (
          <Table
            isLoading={pickupAssignmentState.requestStarted && (pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG")}
            columns={columns}
            data={pickupAssignmentState.pickupsLog}
            rowHeight={48}
            minTableHeight={220}
            noResultsText="No entries"
            infiniteScroll
            infiniteScrollLoading={pickupAssignmentState.fetchPortionStarted && pickupAssignmentState.requestCreator === "GET_PICKUPS_LOG_PORTION"}
            infiniteScrollHasNext={!pickupAssignmentState.pickupsLogRequest.last}
            onInfiniteScroll={onListInfiniteScroll}
          />
        )}
        {pickupAssignmentState.requestFailed && (
          <XGSErrorMessage className="xgs-pickup-log__error">{pickupAssignmentState.requestError || "Error while obtaining the log"}</XGSErrorMessage>
        )}
      </div>

      <PickupDetails
          isOpen={!!pickupDetailsNumber}
          pickup={pickupDetails}
          onClose={() => {
            setPickupDetailsNumber("");
          }}
          isLoading={pickupDetailsState.requestStarted && pickupDetailsState.requestCreator === "GET_PICKUP"}
          readonly
        />
    </ContentContainer>
  );
};

export default PickupLog;
