import React from "react";
import { useSelector} from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui-components/table/table";
import DateCell from "../../../ui-components/table/custom-cells/date-cell/dateCell";
import { exceptionSelector } from "../../../slices/exceptions/exceptionsSlice";

const columnHelper = createColumnHelper<any>();

const ShipmentExceptions: React.FC<{probillNumber: number}> = (props) => {
  const exceptionState = useSelector(exceptionSelector);

  const columns = [
    columnHelper.accessor("addedDate", {
      id: "addedDate",
      header: "Date",
      cell: (cellProps) => <DateCell date={cellProps.getValue()} />,
      size: 100,
    }),
    columnHelper.accessor("terminalNumber", {
      id: "terminalNumber",
      header: "Terminal",
      size: 90
    }),
    columnHelper.accessor("type", {
      id: "type",
      header: "Type",
      size: 120
    }),
    columnHelper.accessor("startedDate", {
      id: "startedDate",
      header: "Started",
      cell: (cellProps) => <DateCell date={cellProps.getValue()} />,
      size: 90,
    }),
    columnHelper.accessor("resolvedDate", {
      id: "resolvedDate",
      header: "Resolved",
      cell: (cellProps) => <DateCell date={cellProps.getValue()} />,
      size: 95,
    }),
    columnHelper.accessor("itemSerial", {
      id: "itemSerial",
      header: "Roll",
      size: 120
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: "Description (limited to 60 characters)",
      size: 330
    })
  ]

  const calcMinTableHeight = () => {
    if (!exceptionState.exceptions) return;
    if (exceptionState.exceptions.length === 2) return 90;
    if (exceptionState.exceptions.length === 3) return 135;
    if (exceptionState.exceptions.length === 4) return 180;
    if (exceptionState.exceptions.length === 5) return 225;
    return (exceptionState.exceptions.length > 5) ? 240 : 80;
  };

  return (
    <>
      <div className="xgs-shipment-details__dock-log">
        <Table
          columns={columns}
          data={exceptionState.exceptions}
          infiniteScroll={true}
          infiniteScrollLoading={false}
          infiniteScrollHasNext={false}
          onInfiniteScroll={() => {}}
          isLoading={exceptionState.loadingExceptions}
          rowHeight={45}
          minTableHeight={calcMinTableHeight()}
          strictMinTableHeight={true}
          responsive
        />
      </div>
    </>
  );
};

export default ShipmentExceptions;
