import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui-components/table/table";
import {
  getStatusHistory,
  shipmentDetailsSelector
} from "../../../slices/shipment-details/shipmentDetailsSlice";
import ShipmentDetailsState from "../../../slices/shipment-details/ShipmentDetailsState";
import Loading from "../../../ui-components/loading/loading";

export interface ShipmentStatusHistoryProps {
  invoiceNumber: number;
}

const columnHelper = createColumnHelper<any>();

const ShipmentStatusHistory: React.FC<ShipmentStatusHistoryProps> = (props) => {
  const dispatch = useDispatch();
  const shipmentDetailsState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);

  const columns = [
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <div className="text-center">
          Date
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue() ? cellProps.getValue().toUiDateFormat() : "" }
        </div>
      ),
      size: 95,
    }),
    columnHelper.accessor("date", {
      id: "time",
      header: () => (
        <div className="text-center">
          Time
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue() ? cellProps.getValue().split("T")[1] : "" }
        </div>
      ),
      size: 75,
    }),
    columnHelper.accessor("title", {
      id: "title",
      header: "Status",
      size: 130
    }),
    columnHelper.accessor("translate", {
      id: "translate",
      header: "Description",
      size: 180
    }),
    columnHelper.accessor("manifestNumber", {
      id: "manifestNumber",
      header: "Manifest Number",
      size: 140
    }),
    columnHelper.accessor("manifestType", {
      id: "manifestType",
      header: "Manifest Type",
      cell: (cellProps) => (
        <>
          { 
            cellProps.getValue() === "D"
              ? "Delivery"
              : (
                  cellProps.getValue() === "L"
                    ? "Linehaul"
                    : cellProps.getValue()
                )
          }
        </>
      ),
      size: 120,
    }),
    columnHelper.accessor("user", {
      id: "user",
      header: "User",
      size: 155
    })  
  ];

  const calcMinTableHeight = () => {
    if (!shipmentDetailsState.statusHistory) return;
    if (shipmentDetailsState.statusHistory.length === 2) return 90;
    if (shipmentDetailsState.statusHistory.length === 3) return 135;
    if (shipmentDetailsState.statusHistory.length === 4) return 180;
    if (shipmentDetailsState.statusHistory.length === 5) return 225;
    return (shipmentDetailsState.statusHistory.length > 5) ? 250 : 80;
  };

  useEffect(() => {
    if (!props.invoiceNumber || shipmentDetailsState.statusHistory) return;
    dispatch(getStatusHistory(props.invoiceNumber));
  }, [dispatch, props.invoiceNumber, shipmentDetailsState.statusHistory]);

  return (
    <>
      {shipmentDetailsState.statusHistoryLoading && (
        <div className="xgs-invoice-documents__loading">
          <Loading isLoading={true} />
        </div>
      )}
      {shipmentDetailsState.statusHistory && shipmentDetailsState.statusHistory.length > 0 && !shipmentDetailsState.statusHistoryLoading && (
        <div className="xgs-shipment-details__status-history">
          <Table
            columns={columns}
            data={shipmentDetailsState.statusHistory}
            infiniteScroll={true}
            infiniteScrollLoading={false}
            infiniteScrollHasNext={false}
            onInfiniteScroll={() => {}}
            rowHeight={45}
            minTableHeight={calcMinTableHeight()}
            strictMinTableHeight={true}
            responsive
          />
        </div>
      )}
      {(!shipmentDetailsState.statusHistory || shipmentDetailsState.statusHistory.length === 0) && shipmentDetailsState.statusHistoryLoaded && (
        <div className="xgs-shipment-details__tabs__no-data">There is no status history</div>
      )}
      {shipmentDetailsState.statusHistoryLoadingFailed && (
        <div className="xgs-shipment-details__tabs__error">
          {shipmentDetailsState.statusHistoryLoadingError || "Error while loading status history"}
        </div>
      )}
    </>
  );
};

export default ShipmentStatusHistory;
