import React from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import { useSelector } from "react-redux";
import buildingIcon from "../../../images/building.svg";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import "./summary.scss";
import { Routes } from "../../../app/route/RoutesConfig";
import { Link } from "react-router-dom";
import { userSelector } from "../../../slices/user/userSlice";
import Table from "../../../ui-components/table/table";
import { getInboundPickupSummaryColumns } from "./getInboundPickupSummaryColumns";
import { useQuery } from "@tanstack/react-query";
import { apiQueryAdapter } from "../../../app/data/apiQueryAdapter";
import { PickupSummaryDto } from "../../../app/data/pickup/models";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";

export const InboundPickupSummary: React.FC<{}> = () => {
  const userState = useSelector(userSelector);

  const {isFetching, isLoading, data, error} = useQuery<PickupSummaryDto[]>(
    ["pickup-summary", userState.activeTerminal?.id],
    async () => {
      let params = { originTerminal: userState.activeTerminal?.id };

      const response = await apiQueryAdapter.query<PickupSummaryDto[]>("/pickups/inbound/summary",
        "GET",
        { params }
      );

      return response.data;
    }
  )
  const terminalAvailable = !!userState.activeTerminal?.id;

  return (
    <ContentContainer isLoading={isFetching} title="Outbound Planning Summary">
      {(!terminalAvailable) && (
          <XGSErrorMessage>
            <div>
              You do not have assigned terminals.
              Email <a className="white-link" href="mailto:helpdesk@xgsi.com">helpdesk@xgsi.com</a> to request terminal assignment for your account.
            </div>
          </XGSErrorMessage>
      )}
      {(error && terminalAvailable) && <XGSErrorMessage>{error}</XGSErrorMessage>}
      {(data && terminalAvailable) && (
        <div className="xgs-pu-summary__tiles-container">
          {!data.length && !isLoading && <div className="xgs-pu-summary__empty">No pickups found for selected terminal.</div>}
          {
            data.map((summary, idx) => {
              return (
                <div key={idx} className="xgs-pu-summary__tile">
                  <div className="xgs-pu-summary__tile__header">
                    <div className="xgs-pu-summary__tile__icon">
                      <img src={buildingIcon} alt=""/>
                    </div>
                    <span>{summary.serviceCenter.airportCode} - {summary.serviceCenter.terminalName.toUpperCase()} ({summary.serviceCenter.terminalNumber})</span>
                    <div className="xgs-pu-summary__tile__header__button">
                      <Link to={"/" + userState.activeTerminal?.id + Routes.pickup.inboundPickups + "?d=" + summary.serviceCenter.terminalNumber}>
                        <Button theme={ButtonThemes.blue} >
                          Show Pickups
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="xgs-pu-summary__tile__body xgs-pu-summary__tile__body--large">
                    <Table
                      columns={getInboundPickupSummaryColumns()}
                      rowHeight={32}
                      responsive
                      data={
                        [
                          {type: "No. of Pickups", planned: summary.assignedSummary?.totalPickups, actual: summary.completedSummary?.totalPickups},
                          {type: "No. of Items", planned: summary.assignedSummary?.totalItems, actual: summary.completedSummary?.totalItems},
                          {type: "Weight", planned: summary.assignedSummary?.totalWeight, actual: summary.completedSummary?.totalWeight},
                          {type: "No. of Drivers", planned: summary.assignedSummary?.totalDrivers, actual: summary.completedSummary?.totalDrivers},
                        ]
                      }
                    />
                  </div>
                  <div className="xgs-pu-summary__tile__body xgs-pu-summary__tile__body--small">
                    <Table
                      columns={getInboundPickupSummaryColumns()}
                      rowHeight={32}
                      responsive
                      data={
                        [
                          {type: "Pickups", planned: summary.assignedSummary?.totalPickups, actual: summary.completedSummary?.totalPickups},
                          {type: "Items", planned: summary.assignedSummary?.totalItems, actual: summary.completedSummary?.totalItems},
                          {type: "Weight", planned: summary.assignedSummary?.totalWeight, actual: summary.completedSummary?.totalWeight},
                          {type: "Drivers", planned: summary.assignedSummary?.totalDrivers, actual: summary.completedSummary?.totalDrivers},
                        ]
                      }
                    />
                    <div className="xgs-pu-summary__tile__body__button">
                      <Link to={"/" + userState.activeTerminal?.id + Routes.pickup.inboundPickups + "?d=" + summary.serviceCenter.terminalNumber}>
                        <Button theme={ButtonThemes.blue} >
                          Show Pickups
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      )}
    </ContentContainer>
  )
}