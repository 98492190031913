import api, { ApiResponse } from "../api";
import axios, { CancelTokenSource } from "axios";
import { RemittanceAdviceResponseModel, RemittanceInvoiceResponseModel, RemittanceInvoicesFilterModel } from "./models";
import { urlParams } from "../../../services/common/urlParams";

export const REQUEST_ID: {
  [key: string]: string;
} = {
  GET_REMITTANCE_INVOICES: "GET_REMITTANCE_INVOICES",
  GET_REMITTANCE_ADVICES: "GET_REMITTANCE_ADVICES",
  GET_ADVICE_EMAIL: "GET_ADVICE_EMAIL",
};

class RemittanceInvoicesService {
  private source: {
    [key: string]: CancelTokenSource;
  };

  private static instance: RemittanceInvoicesService;

  private constructor() {
    this.source = {};
  };

  static getInstance(): RemittanceInvoicesService {
    if (!RemittanceInvoicesService.instance) {
      RemittanceInvoicesService.instance = new RemittanceInvoicesService();
    }
    return RemittanceInvoicesService.instance;
  };

  public getAdvices = async (
    filter: RemittanceInvoicesFilterModel,
    pageId?: string,
  ): Promise<ApiResponse<RemittanceAdviceResponseModel>> => {
    this.source[REQUEST_ID.GET_REMITTANCE_ADVICES] && this.source[REQUEST_ID.GET_REMITTANCE_ADVICES].cancel();
    this.source[REQUEST_ID.GET_REMITTANCE_ADVICES] = axios.CancelToken.source();
    return await api.get("/proxy/finance/remittance/advices", { params: urlParams({ ...filter, next: pageId }), cancelToken: this.source[REQUEST_ID.GET_REMITTANCE_ADVICES].token });
  };

  public getAdviceInvoices = async (
    adviceId: number,
  ): Promise<ApiResponse<RemittanceInvoiceResponseModel>> => {
    this.source[REQUEST_ID.GET_REMITTANCE_INVOICES] && this.source[REQUEST_ID.GET_REMITTANCE_INVOICES].cancel();
    this.source[REQUEST_ID.GET_REMITTANCE_INVOICES] = axios.CancelToken.source();
    return await api.get(`/proxy/finance/remittance/advices/${adviceId}/invoices`, { cancelToken: this.source[REQUEST_ID.GET_REMITTANCE_INVOICES].token });
  };

  public getAdviceEmail = async (
    adviceId: number,
  ): Promise<ApiResponse<string>> => {
    this.source[REQUEST_ID.GET_ADVICE_EMAIL] && this.source[REQUEST_ID.GET_ADVICE_EMAIL].cancel();
    this.source[REQUEST_ID.GET_ADVICE_EMAIL] = axios.CancelToken.source();
    return await api.get(`/proxy/finance/remittance/advices/${adviceId}/email/mime/stream`, { cancelToken: this.source[REQUEST_ID.GET_ADVICE_EMAIL].token });
  };

  public getCsvExport = async (
    params: {
      adviceIds?: string,
      isIncludeAllResults?: boolean,
    }
  ): Promise<ApiResponse<string>> => {
    this.source[REQUEST_ID.GET_ADVICE_EMAIL] && this.source[REQUEST_ID.GET_ADVICE_EMAIL].cancel();
    this.source[REQUEST_ID.GET_ADVICE_EMAIL] = axios.CancelToken.source();
    return await api.get('/proxy/finance/remittance/advices/invoice/export', { cancelToken: this.source[REQUEST_ID.GET_ADVICE_EMAIL].token, params: urlParams(params) });
  };
};

export default RemittanceInvoicesService;
